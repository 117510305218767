import React from "react";
import Reports from "./Pages/reports";
import Ticketsystem2Overview from "./Pages/overview";
import Inbox from "./Pages/inbox";
import Ticket from "./Pages/ticket";
import EmailSettings from "./Pages/EmailSettings";
import CPS from "./Pages/CPS";
import {CryptDecrypt} from "../../@WUM/core/Elements/crypt/Crypt";
import TicketNew from "./Pages/ticketNew";
import {LanguageSwitcherValue} from "../ticketsystem/modules/components/LanguageSwitcher";

export const Ticketsystem2Config = (value:any) => {

    if(localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystem === 0) {
        return []
    } else {
        return [
            {
                path: '/ticketsystem/overview/:page',
                elements: <Ticketsystem2Overview value={value} />,
                roles: ['superadmin', 'admin', 'ticketsystem']
            },
            {
                path: '/ticket/ticketsystem/:page',
                elements: <Inbox value={value} />,
                roles: ['superadmin', 'admin', 'ticketsystem']
            },
            {
                path: '/ticket/single/ticketsystem/:id',
                elements: <TicketNew value={value} />,
                roles: ['superadmin', 'admin', 'ticketsystem']
            },/*
        {
            path: '/email/ticket/:page',
            elements: <EmailSettings value={value} />,
            roles: ['superadmin', 'admin', 'ticketsystem']
        },*/
            {
                path: '/generell/Settings/ticket',
                elements: <CPS value={value} />,
                roles: ['superadmin', 'admin', 'ticketsystem']
            },

            {
                path: '/support/ticketsystem/report/:page',
                elements: <Reports value={value} />,
                roles: ['superadmin', 'admin', 'ticketsystem']
            }
        ]
    }


}

export const Ticketsystem2MenuConfig = (value:any) => {

    if(localStorage.getItem('userRights') !== null && localStorage.getItem('userRights') !== undefined && JSON.parse(CryptDecrypt(localStorage.getItem('userRights') as string) as string).ticketsystem === 0) {
        return []
    } else {

        return [
            {
                header: 'Ticket System 2.0',
                icon: 'import_contacts',
                roles: ['superadmin', 'admin', 'ticketsystem'],
                menuitem: [
                    {
                        name: LanguageSwitcherValue('common.dashbaord'),
                        route: '/ticketsystem/overview/1',
                        icon: 'dashboard',
                        roles: ['superadmin', 'admin', 'ticketsystem']
                    },
                    {
                        name: LanguageSwitcherValue('common.tickets'),
                        route: '/ticket/ticketsystem/1',
                        icon: 'move_to_inbox',
                        roles: ['superadmin', 'admin', 'ticketsystem']
                    },
                    /*{
                        name: 'Email Settings',
                        route: '/email/ticket/1',
                        icon: 'email',
                        roles: ['superadmin', 'admin', 'ticketsystem']
                    },*/{
                        name: LanguageSwitcherValue('common.general.settings'),
                        route: '/generell/Settings/ticket',
                        icon: 'settings',
                        roles: ['superadmin', 'admin', 'ticketsystem']
                    },
                    /*
                    {
                        name: 'Tickets',
                        route: '/ticket/ticketsystem/:page',
                        icon: 'move_to_inbox',
                        roles: ['superadmin', 'admin', 'ticketsystem']
                    },*/
            {
                name: LanguageSwitcherValue('common.report_stats'),
                route: '/support/ticketsystem/report/1',
                icon: 'query_stats',
                roles: ['superadmin', 'admin', 'ticketsystem']
            },/*
            {
                name: 'Authorization',
                route: '/ticketsystem/overview/1',
                icon: 'verified_user',
                roles: ['superadmin', 'admin', 'ticketsystem']
            },*/
                ]
            }
        ]
    }
}

export const Software_Support = () => [
    {
        title: 'Dashboard',
        href: '/ticketsystem/overview/1',
        undertitle: 'Ticket System 2.0',
        icon: 'dashboard',
        text: 'Ticket System 2.0',
        roles: ['superadmin', 'admin', 'ticketsystem']
    },
    {
        title: 'Tickets',
        href: '/ticket/ticketsystem/1',
        undertitle: 'Ticket System 2.0',
        icon: 'move_to_inbox',
        text: 'Ticket System 2.0',
        roles: ['superadmin', 'admin', 'ticketsystem']
    }
]
