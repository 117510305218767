import {ticketsystem2ApiType} from "./ticketsystem2Api.interface";
import {requests} from "../../../../@WUM/core/RestFullApi/api";
import {ticketApiType} from "./ticket.interface";
import {memberApiType} from "../../../ticketsystem/API/request/memberApi.interface";
import {dashboardApiInterface} from "./dashboard.interface";
import {PrioApiType} from "../../../ticketsystem/API/request/Prio.interface";
import {LabelsApiType} from "../../../ticketsystem/API/request/Labels.interface";

export const ticketsystem2 = {
    getPosts: (archiv:any): Promise<ticketApiType[]> =>
        requests.get(`${archiv}/yoptimize/ticket/`),
    getPostsSingle: (id:any): Promise<ticketApiType[]> =>
        requests.get(`yoptimize/ticket/${id}/`),
    getPostsTrigger: (): Promise<LabelsApiType[]> =>
        requests.get(`yoptimize/fetch/system/trigger/TicketSystem/`),
    getPostsSingleComment: (id:any): Promise<ticketApiType[]> =>
        requests.get(`yoptimize/Comment/${id}/`),
    createPost: (post: ticketsystem2ApiType): Promise<ticketsystem2ApiType> =>
        requests.post('save/activity_recording/yoptimize/', post),
    updatePost: (post: any, id: number): Promise<ticketsystem2ApiType> =>
        requests.put(`User/update/${id}/`, post),
    deletePost: (id: any): Promise<void> =>
        requests.delete(`User/delete/${id}/`),
    getPostsTaskDashbaord: (id:any): Promise<dashboardApiInterface> =>
        requests.get(`yoptimize/reports/ticket/`),
    getPostsTemplate: (): Promise<PrioApiType[]> =>
        requests.get(`template/ticket/yoptimize/`),
    getPostsNotifications: (): Promise<PrioApiType[]> =>
        requests.get(`notifications/ticket/yoptimize/`),
    createDepartment: (post: ticketsystem2ApiType,eid:any,did:any): Promise<ticketsystem2ApiType> =>
        requests.post(`yoptimize/email/department/save/${eid}/${did}/`, post),
}
