import '../css/profil.css'
import React, {useEffect, useState} from "react";
import {
    FileUploadButton, IntlMessageValue, Regex,
    UniButton,
    UniCards,
    UniCol,
    UniForm,
    UniRow,
    UniSpinner
} from "../../../../@WUM/core/Elements";
import {ResponseDeparmtent, ResponseUSerProfil} from "../API/response/support_software";
import {_UserInterface} from "../API/request/SupportSoftwareApi.interface";
import {config, output} from "./configuration/mapping";
import {support_software} from "../API/request/support_software";
import { notify_save } from '../../../../@WUM/core/component/const/notification.service';
import { error, IntlMessage } from '../../../../@WUM/core/Elements/index'
import init from '../../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { Form } from 'react-bootstrap'
import Stats from "../../../../@WUM/core/Elements/Stats";
import {ResponseAllSRoles, ResponseAllUser} from "../../Member/API/response/member/member";
import Country, { enabledFederalState } from '../../../../@WUM/core/Elements/Country';
import ReactDOM from "react-dom";


const Profil = (props:{value:any}) =>{

    const departments = ResponseDeparmtent(0);
    const Profils = ResponseUSerProfil(localStorage.getItem('userid'));
    const Store = useSelector((state: any) => state)
    const history = useNavigate()
    // @ts-ignore
    let Profil:_UserInterface[] = [Profils !== undefined ? Profils: undefined]
    /**
     * Neuladen der Seite
     */
    const routeChange = (path: string) => {
        let paths = path
        history(paths)
    }
    const [currentTutorial, setCurrentTutorial] = useState<_UserInterface[]>(
         typeof Profil !== "boolean" && Profil !== undefined ? Profil : []
    )
    const Send = (event:any) => {
        event.preventDefault()
        event.stopPropagation()

        /*
               *  Wichtig zum Rausholen der Datan aus Formular und senden
               *  Das Interface muss immer angepasst werden
               */
        let Data = init()

        let reauestData: _UserInterface[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        support_software
            .updatePost(reauestData, localStorage.getItem('userid') as string)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save(Store)
                routeChange(`/profil/overview/1` )
            })
            .catch((e:any) => {
                error(e)
            })
    }

    const Roles = ResponseAllSRoles()
    const AllUSer = ResponseAllUser()



    if (departments !== null && typeof departments !== "boolean" && departments !== undefined && Profils !== true && typeof Profils !== "boolean" && Profils !== undefined && localStorage.getItem('Roles') !== undefined && localStorage.getItem('AllUser') !== undefined && localStorage.getItem('Roles') !== null && localStorage.getItem('AllUser') !== null) {
        enabledFederalState({value:Profil[0].country,id:'federalState'})
        let departmentHolder = [];
        let positionHolder = [];
        for(let d=0;d<departments.length;d++) {
            let tmp = departments[d];

            departmentHolder.push(<>
                    <option value={tmp.value}>{IntlMessageValue('de', tmp.name, Store)}</option>
                </>
            )

            if(Profil[0].department == tmp.value) {
                for(let p=0;p<tmp.positions.length;p++){

                    if(tmp.positions[p] === undefined ) {

                    } else {
                        positionHolder.push(
                            <>
                                <option value={tmp.positions[p].value}>{IntlMessageValue('de', tmp.positions[p].name, Store)}</option>
                            </>
                        )
                    }

                }

            }

        }

        const HandlePosition = (department:any)=> {
            let NewPositionHolder = [];
            for(let d=0;d<departments.length;d++) {
                let tmp = departments[d];


                if(department == tmp.value) {
                    for(let p=0;p<tmp.positions.length;p++){

                        if(tmp.positions[p] === undefined ) {

                        } else {
                            NewPositionHolder.push(
                                <>
                                    <option value={tmp.positions[p].value}>{IntlMessageValue('de', tmp.positions[p].name, Store)}</option>
                                </>
                            )
                        }

                    }

                }

            }
            let el = document.getElementById('position')
            if (el !== undefined && el !== null) {
                el.remove()
            };
            let newElement = <>
                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.position', Store)}/>
                <Form.Select name={'position'}  className={'mb-3'}
                                          defaultValue={Profil[0].position}>
                <option label={""}></option>
                {NewPositionHolder}
            </Form.Select></>;
            let temp = document.createElement('div')
            temp.classList.add("mb-3");
            temp.classList.add("input-group");
            temp.id='position'
            ReactDOM.render(newElement, temp)
            let ele = document.getElementById('appendposition')
            if (ele !== null) {
                ele.appendChild(temp)
            }
        }

        return (
            <>
                <Form
                    id={'form001'}
                    noValidate
                    onSubmit={(e) =>
                        Send(e)
                    }
                >
                <div className={'ticketsystem'} style={{padding: '25px'}}>
                    <UniCards class={'p-3'}>
                        <h3><IntlMessage Store={Store} messageId="profil.header"/></h3>
                        <p>
                            <IntlMessage Store={Store} messageId="profil.description"/>
                        </p>
                        <UniRow>
                            <UniCol class={'col-3 p-3'}>
                                    <FileUploadButton
                                        classNameInput={'d-none'}
                                        img={{width: '300px', height: '300px'}} defaultValue={Profil[0].media}
                                        classNameButton={'mt-3'} defaultSRC={Profil[0].media}
                                        styleButton={{width: '100%'}} placeholderImg={'/assets/avatarPlaceholder.png'}
                                        ButtonLabel={IntlMessageValue('de', 'profile_picture.button.label', Store)}
                                        DelButtonLabel={IntlMessageValue('de', 'profile_picture.delbutton.label', Store)}
                                        name={'media'}
                                        accept={"image/png, image/jpeg, image/webp"}/>
                                <Stats
                                    preText={IntlMessageValue('de', 'valid.profil.preText', Store)}
                                    afterText={IntlMessageValue('de', 'valid.profil.afterText', Store)}
                                    validText={IntlMessageValue('de', 'valid.profil.validText', Store)}
                                    replaceName={'[PercentHolder]'} validAfterName={IntlMessageValue('de', 'valid.profil.validAfterName', Store)} validIcon={'verified_user'} ValidIconvariant={'-outlined'}
                                    item={[
                                        {title:IntlMessageValue('de', 'common.profile_picture', Store),percent:10,check: (Profil[0].media !== undefined && Profil[0].media !== null && Profil[0].media !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.responsibility', Store),percent:6,check: (Profil[0].responsibility !== undefined && Profil[0].responsibility !== null && Profil[0].responsibility !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.username', Store),percent:6,check: (Profil[0].username !== undefined && Profil[0].username !== null && Profil[0].username !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.gender', Store),percent:6,check: (Profil[0].gender !== undefined && Profil[0].gender !== null && Profil[0].gender !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.firstname', Store),percent:6,check: (Profil[0].first_name !== undefined && Profil[0].first_name !== null && Profil[0].first_name !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.lastname', Store),percent:6,check: (Profil[0].last_name !== undefined && Profil[0].last_name !== null && Profil[0].last_name !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.birthday', Store),percent:6,check: (Profil[0].birthday !== undefined && Profil[0].birthday !== null && Profil[0].birthday !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.phone', Store),percent:6,check: (Profil[0].phone !== undefined && Profil[0].phone !== null && Profil[0].phone !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.email', Store),percent:6,check: (Profil[0].phone !== undefined && Profil[0].phone !== null && Profil[0].phone !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.location', Store),percent:6,check: (Profil[0].country !== undefined && Profil[0].country !== null && Profil[0].country !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.federalState', Store),percent:6,check: (Profil[0].federalState !== undefined && Profil[0].federalState !== null && Profil[0].federalState !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.city', Store),percent:6,check: (Profil[0].city !== undefined && Profil[0].city !== null && Profil[0].city !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.department', Store),percent:6,check: (Profil[0].department !== undefined && Profil[0].department !== null && Profil[0].department !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.position', Store),percent:6,check: (Profil[0].position !== undefined && Profil[0].position !== null && Profil[0].position !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.firstStandIn', Store),percent:6,check: (Profil[0].firstStandIn !== undefined && Profil[0].firstStandIn !== null && Profil[0].firstStandIn !== '' ? true : false)},
                                        {title:IntlMessageValue('de', 'common.secondStandIn', Store),percent:6,check: (Profil[0].secondStandIn !== undefined && Profil[0].secondStandIn !== null && Profil[0].secondStandIn !== '' ? true : false)}
                                    ]} />
                                </UniCol>
                                <UniCol>
                                    <UniRow>
                                        <UniCol class={'col-4 '}>
                                        </UniCol>
                                        <UniCol class={'mcenter'}>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.default_language', Store)}/>
                                                <UniForm.Select defaultValue={Profil[0].locale_code} name={'locale_code'}   items={[
                                                    {value: '', text: ''},
                                                    {value: 'DE', text: IntlMessageValue('de', 'common.german', Store)},
                                                    {value: 'US', text: IntlMessageValue('de', 'common.english', Store)}
                                                ]}/>
                                            </UniForm.InputGroup>
                                        </UniCol>
                                        <UniCol class={'col-4'}>
                                            <UniForm.FormControl name={'uid'} defaultValue={Profil[0].uid} required={true}
                                                                 type={'hidden'}/>
                                        </UniCol>
                                    </UniRow>
                                    <UniRow>
                                        <UniCol class={'border-right  p-3'}>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.responsibility', Store)}/>
                                                <UniForm.ToggleSelect defaultValue={Profil[0].responsibility} short={'responsibility'} style={{display:"block"}} id={'ResponsibilityMaker'} name={'responsibility'}
                                                                      ToggleButton={[{value:'Intern',label:'Intern'},{value:'Extern',label:'Extern'}]} />

                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.username', Store)}/>
                                                <Form.Control type="text" required={true} name={'username'} defaultValue={Profil[0].username} onKeyPress={(e)=>Regex(e,/[ A-Za-z0-9&.]/,IntlMessageValue('de', 'error.regex', Store))} />
                                            </UniForm.InputGroup>

                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.gender', Store)}/>
                                                <UniForm.ToggleSelect defaultValue={Profil[0].gender} short={'gender'} style={{display:"block"}} id={'GederMaker'} name={'gender'}
                                                                      ToggleButton={[{value:'Männlich',label:IntlMessageValue('de', 'common.gender.male', Store)},{value:'Weiblich',label:IntlMessageValue('de', 'common.gender.female', Store)},{value:'Diverse',label:IntlMessageValue('de', 'common.gender.various', Store)}]} />
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.firstname', Store)}/>
                                                <UniForm.FormControl name={'first_name'} defaultValue={Profil[0].first_name} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.lastname', Store)}/>
                                                <UniForm.FormControl name={'last_name'} defaultValue={Profil[0].last_name} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.birthday', Store)}/>
                                                <UniForm.FormControl name={'birthday'} defaultValue={Profil[0].birthday} required={true}
                                                                     type={'date'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.phone', Store)}/>
                                                <UniForm.FormControl name={'phone'} defaultValue={Profil[0].phone} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.email', Store)}/>
                                                <UniForm.FormControl name={'email'} defaultValue={Profil[0].email} required={true}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <br/>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.newpassword', Store)}/>
                                                <UniForm.FormControl name={'password'} defaultValue={''} required={true}
                                                                     type={'password'}/>
                                            </UniForm.InputGroup>
                                        </UniCol>
                                        <UniCol class={'p-3'}>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'common.location', Store)}/>
                                                <Country defaultValue={Profil[0].country} mode={'country'}
                                                         name={'country'} className={'mb-3'}
                                                         onChange={(e) => enabledFederalState({
                                                             e: e,
                                                             id: 'federalState'
                                                         })}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup id={'federalState'}>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'common.federalState', Store)}/>
                                                <Country defaultValue={Profil[0].federalState} mode={'federalState'}
                                                         name={'federalState'} className={'mb-3'}/>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'common.city', Store)}/>
                                                <UniForm.FormControl name={'city'} defaultValue={Profil[0].city}
                                                                     type={'text'}/>
                                            </UniForm.InputGroup>
                                            <br/><br/>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'common.department', Store)}/>
                                                {/*<UniForm.FormControl name={'department'} defaultValue={Profil[0].department} type={'text'}/>*/}

                                                <Form.Select name={'department'} className={'mb-3'}
                                                             defaultValue={Profil[0].department}
                                                             onChange={(e) => HandlePosition(e.target.value)}>
                                                    <option label={""}></option>
                                                    {departmentHolder}
                                                </Form.Select>

                                            </UniForm.InputGroup>
                                            <div id={'appendposition'}>
                                            <UniForm.InputGroup id={'position'}>

                                                {/*<UniForm.FormControl name={'position'} defaultValue={Profil[0].position} type={'text'}/>*/}

                                                <UniForm.InputGroupText
                                                    text={IntlMessageValue('de', 'common.position', Store)}/>
                                                    <Form.Select name={'position'}  className={'mb-3'}
                                                                 defaultValue={Profil[0].position}>
                                                        <option label={""}></option>
                                                        {positionHolder}
                                                    </Form.Select>

                                            </UniForm.InputGroup>
                                                </div>
                                        <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.role', Store)}/>
                                                <Form.Select  name={'discr'} className={'mb-3'} defaultValue={Profil[0].discr}>
                                                    {JSON.parse(localStorage.getItem('Roles') as string).map((route:any, index:any) =>
                                                        <option value={route.code}>{route.name}</option>
                                                    )}
                                                </Form.Select>
                                            </UniForm.InputGroup>
                                            <br/><br/>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.firstStandIn', Store)}/>
                                                <Form.Select name={'firstStandIn'} className={'mb-3'}
                                                             defaultValue={Profil[0].firstStandIn as string}>
                                                    <option
                                                        label={IntlMessageValue('de', 'common.no.second', Store)}></option>
                                                    {JSON.parse(localStorage.getItem('AllUser') as string).map((route: any, index: any) =>
                                                        <option value={route.id as string}>{route.username}</option>
                                                    )}
                                                </Form.Select>
                                            </UniForm.InputGroup>
                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={IntlMessageValue('de', 'common.secondStandIn', Store)}/>
                                                <Form.Select  name={'secondStandIn'} className={'mb-3'} defaultValue={Profil[0].secondStandIn as string}>
                                                    <option label={IntlMessageValue('de', 'common.no.second', Store)}></option>
                                                    {JSON.parse(localStorage.getItem('AllUser') as string).map((route:any, index:any) =>
                                                        <option value={route.id  as string}>{route.username}</option>
                                                    )}
                                                </Form.Select>
                                            </UniForm.InputGroup>
                                        </UniCol>
                                    </UniRow>
                                </UniCol>
                            </UniRow>
                            <br/><br/>

                        <UniButton type={'submit'} variant={'success'}>{IntlMessageValue('de', 'common.save', Store)}</UniButton>
                    </UniCards>
                </div>
                </Form>
            </>
        )
    } else {
        return(
            <UniSpinner text={'Loading...!'} variant={'secondary'} />
        )
    }
}

export default Profil;
