import React from "react";
import Usestats from './Pages/overview'

export const UsestatsConfig = (value: any) => {

    return [
        {
            path: '/usestats/overview/:page',
            elements: <Usestats value={value}/>,
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']
        }
    ]
}

export const UsestatsMenuConfig = (value: any) => {
    return [

        {
            name: 'common.usage_statistics',
            route: '/usestats/overview/1',
            icon: 'query_stats',
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']

        }
    ]
}

export const Software_Support = () => [
    {
        title: 'Support Ticket',
        href: '/support/software/overview/1',
        undertitle: 'Software-Support',
        icon: 'manage_accounts',
        text: 'Software-Support',
        roles: ['superadmin', 'admin',
            'ticketsystem',
            'worktime',
            'teamtakt']
    }
]
