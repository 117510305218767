import Form from 'react-bootstrap/Form';
import React, {ChangeEvent, CSSProperties, ElementType, KeyboardEventHandler, MouseEventHandler} from "react";


interface UniFormControlProps {
    ref?:any ;
    /** See Legale Value https://www.w3schools.com/html/html_form_input_types.asp*/
    type?:string,
    /** Set Name Attribute on Element */
    name:string,
    /** Set Placeholder on Element */
    placeholder?:string,
    /** Set custom Classes on Element */
    class?:string,
    /** Set DefaultValue on Element */
    defaultValue?:string,
    /** Make Element Required with val true */
    required?:boolean,
    /** Combine Element with Onchange Function and Event */
    onChange?: (event: ChangeEvent) => void
    /** set id on Element in DOM */
    id?:string
    /** Set inline style for Element */
    style?:CSSProperties | undefined
    /** You can use a custom element type for this component. */
    as?:ElementType<any> | undefined
    /** Set Textarea Rows */
    rows?:number
    /** Combine Element with onKeyPress Function and Event */
    onKeyPress?:KeyboardEventHandler | undefined
    onClick?:MouseEventHandler
    accept?:string
    readOnly?:boolean;
}

/**
 * Create a Input Field or Textarea with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/form-control#api" target="_blank">Form.Control</a>.<br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <UniForm validated={validated} Submit={handleSubmit}>
 *    <UniForm.FormGroup>
 *       <UniForm.FormControl type={'text'} name={'name'} placeholder={'placeholder'}  />
 *    </UniForm.FormGroup>
 * </UniForm>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */

const UniFormControl = (props:UniFormControlProps) => {

    if(props.type === 'number') {
        return(
            <Form.Control type={props.type}
                          name={props.name}
                          readOnly={props.readOnly !== undefined ? props.readOnly : false}
                          id={props.id} autoComplete="new-password"
                          ref={props.ref}
                          style={props.style}
                          placeholder={props.placeholder}
                          className={props.class}
                          as={props.as}
                          rows={props.rows}
                          min="0" max="1000"
                          accept={props.accept}
                          defaultValue={props.defaultValue}
                          required={props.required !== undefined ? props.required : false}
                          onKeyPress={props.onKeyPress}
                          onClick={props.onClick}
                          onChange={props.onChange}/>
        )
    } else {
        return(
            <Form.Control type={props.type}
                          name={props.name}
                          readOnly={props.readOnly !== undefined ? props.readOnly : false}
                          id={props.id} autoComplete="new-password"
                          ref={props.ref}
                          style={props.style}
                          placeholder={props.placeholder}
                          className={props.class}
                          as={props.as}
                          rows={props.rows}
                          accept={props.accept}
                          defaultValue={props.defaultValue}
                          required={props.required !== undefined ? props.required : false}
                          onKeyPress={props.onKeyPress}
                          onClick={props.onClick}
                          onChange={props.onChange}/>
        )
    }

}

export default UniFormControl;
