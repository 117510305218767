import React, { useState } from 'react'
import SidebarItems from '../../templates/default/config/SidebarItems'
import {
    Sidebar as SidebarT,
    Menu,
    useProSidebar,
    SubMenu
} from 'react-pro-sidebar'

import ItemMenu from './ItemMenu'
import MenuIcon from './MenuIcon'
import { IntlMessageValue, Gallery } from '../Elements/index'
import ChangeHeader from './ChangeHeader'
import { useSelector } from 'react-redux'
import './sidebar.css'
import SecureCheck from '../RoleSecury'
import { mappingSubmenu } from './mappingSubmenu'
import {Link} from "react-router-dom";
import {MIcon} from "../Elements";
import Favourite from "../Favo/modules/Favourite";

const Sidebar = (props: { setValue: any; value: any }) => {
    const [menuCollapse, setMenuCollapse] = useState(false)
    let Store = useSelector((state: any) => state)
    const [active, setActive] = useState('')
    const { collapseSidebar } = useProSidebar()

    const menuIconClick = () => {
        let Header = document.getElementsByClassName(
            'theme-header'
        )[0] as HTMLDivElement
        let Footer = document.getElementsByClassName(
            'theme-footer'
        )[0] as HTMLDivElement
        if (menuCollapse) {
            setMenuCollapse(false)
            collapseSidebar()
            Header.style.marginLeft = '-270px'
            Footer.style.marginLeft = '-270px'
            Footer.style.zIndex = '1008'
        } else {
            setMenuCollapse(true)
            collapseSidebar()
            Header.style.marginLeft = '-80px'
            Footer.style.marginLeft = '-80px'
            Footer.style.zIndex = '1009'
        }
    }

    return (
        <>
            <SidebarT className={' pro-sidebar sidebarBackground'}>
                <div className={'sidebarHeader m-0'}>
                    {menuCollapse ? (

                            <Link className={''} to={
                                process.env.REACT_APP_LANDINGPAGES !== undefined
                                    ? process.env.REACT_APP_LANDINGPAGES
                                    : '/Blog/overview/1'
                            } >

                            <img
                                src={'/'+process.env.REACT_APP_LOGO}
                                alt={'Logo'}
                                id={'logoFavicon'}
                                className={'SidebarLogo'}
                                style={{width:'60px',height:'60px',padding:'5px'}}
                            />
                            </Link>
                    ) : (
                        <Link className={''} to={
                            process.env.REACT_APP_LANDINGPAGES !== undefined
                                ? process.env.REACT_APP_LANDINGPAGES
                                : '/Blog/overview/1'
                        } >
                            <img
                                src={process.env.REACT_APP_LOGO_IMG}
                                height={60}
                                alt={'Logo'}
                                id={'logo'}
                                className={'SidebarLogo'}
                            />
                        </Link>
                    )}
                </div>
                <div className={'SidebarContent'}>
                    <div className="logotext"></div>
                    <div
                        className="closemenu box mb-1"
                        style={{background: '#E5F1FD',paddingLeft: '10px',paddingBottom:'5px',borderBottom: '1px solid #23AAE1'}}

                    >
                        <h3 className={'mb-3'}>
                            {process.env.REACT_APP_FAVICON !== undefined && !menuCollapse ?
                            <div className={'float-start'} >
                                {JSON.parse(process.env.REACT_APP_FAVICON).map((
                                    route: { icon: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; variant:  'fill' | '-outlined' | '-rounded' | '-sharp' | undefined; className: string | undefined ;label: string | undefined;link: string | undefined},
                                    index: any) =>
                                    <>{route.icon === 'star' ?
                                            <Favourite name={'start'}
                                                       key={'start'} button={'icon'}
                                                       placement={'start'}
                                                       newFavo={true}/>
                                            :
                                            <Link to={route.link as string}>
                                                <MIcon children={route.icon} variant={route.variant}
                                                       className={route.className}/>
                                            </Link>
                                    }</>
                                )}
                            </div>
                                :<></> }
                            {menuCollapse ? (
                                <svg onClick={() => menuIconClick()}
                                    className={'float-start ml-2'}
                                    stroke="currentColor"
                                    fill="white"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <polyline points="12 16 16 12 12 8"></polyline>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                            ) : (
                                <svg onClick={() => menuIconClick()}
                                    className={'float-end mr-2'}
                                    stroke="currentColor"
                                    fill="white"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <polyline points="12 8 8 12 12 16"></polyline>
                                    <line x1="16" y1="12" x2="8" y2="12"></line>
                                </svg>
                            )}
                            <br style={{clear: 'both'}}/>
                        </h3>
                        <br style={{clear: 'both'}}/>
                    </div>
                    {process.env.REACT_APP_FAVICON !== undefined && menuCollapse ?
                        <>
                        <nav className="ps-menu-root css-vj11vy">
                            <ul className="css-ewdv3l">

                            <li className="ps-menuitem-root ps-submenu-root first css-16jesut"><a onClick={()=>{
                                if ((document.getElementById('ShortMenuCollapse') as HTMLDivElement).classList.contains('css-1tlaihp')) {
                                    (document.getElementById('ShortMenuCollapse') as HTMLDivElement).classList.add('css-1l5fxv3');
                                    (document.getElementById('ShortMenuCollapse') as HTMLDivElement).classList.remove('css-1tlaihp');
                                } else {
                                    (document.getElementById('ShortMenuCollapse') as HTMLDivElement).classList.remove('css-1l5fxv3');
                                    (document.getElementById('ShortMenuCollapse') as HTMLDivElement).classList.add('css-1tlaihp');
                                }
                            }}
                                className="ps-menu-button" data-testid="ps-submenu-content-test-id"><span
                                className="ps-menu-icon css-wx7wi4"><span className="material-icons-outlined"
                                                                          aria-hidden="true">menu</span></span><span
                                className="ps-menu-label css-12w9als">ShortMenu</span><span
                                className="ps-submenu-expand-icon css-um1o6k"><span
                                className="css-1097eso"></span></span></a>
                                <div id={'ShortMenuCollapse'} data-testid="ps-submenu-content-test-id" style={{position:'fixed',inset:'0px auto auto 0px',margin:'0px',transform:"translate(85px, 94px)"}}
                                     className="ps-submenu-content css-1tlaihp"
                                     data-popper-escaped="" data-popper-placement="right">
                                    <ul className="css-ewdv3l">

                                {JSON.parse(process.env.REACT_APP_FAVICON).map((route: {
                                    icon: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined;
                                    variant: 'fill' | '-outlined' | '-rounded' | '-sharp' | undefined;
                                    className: string | undefined;
                                    label: string | undefined
                                    link: string | undefined
                                }, index: any) =>
                                    <>

                                        {route.icon === 'star' ?
                                        <Favourite name={'start'}
                                                   key={'start'} button={'list'}
                                                   placement={'start'}
                                                   newFavo={true}/>
                                            :

                                        <li className="ps-menuitem-root MenuItem deactiveSidebar unLink css-16jkw2k">
                                            <Link to={route.link as string} className="ps-menu-button" data-testid="ps-menu-button-test-id">
                                                <span
                                                    className="ps-menu-icon css-wx7wi4"><MIcon children={route.icon}
                                                                                               variant={route.variant}
                                                                                               className={route.className}/></span>
                                                <span
                                                    className="ps-menu-label css-12w9als">{route.label}</span>
                                            </Link>
                                        </li>
                                        }
                                    </>
                                )}</ul>
                                </div>
                            </li>
                            </ul>
                        </nav>
                        </>
                        : <></>
                    }
                    {SidebarItems(props.value).map((item, index) => {
                        if (
                            item.icon !== undefined &&
                            item.icon !== '' &&
                            SecureCheck(item.roles)
                        ) {
                            return (
                                <>
                                    <Menu key={'Menu' + index}>

                                        <SubMenu
                                            defaultOpen={mappingSubmenu(
                                                IntlMessageValue(
                                                            props.value,
                                                            item.header,
                                                            Store
                                                        ),
                                                        active
                                                    )}
                                                    key={'SubMenu' + index}
                                                    label={ChangeHeader({
                                                        header: item.header,
                                                        value: props.value,
                                                        Store: Store
                                                    })}
                                                    icon={
                                                        <MenuIcon
                                                            icon={
                                                                item.icon !== undefined
                                                                    ? item.icon
                                                                    : ''
                                                            }
                                                        />
                                                    }
                                                    className={'first'}
                                                >
                                                    <ItemMenu
                                                        header={item.header}
                                                        active={active}
                                                        setActive={setActive}
                                                        key={'ItemMenu' + index}
                                                        items={item.menuitem}
                                                        value={props.value}
                                                        Store={Store}
                                                    />
                                                </SubMenu>
                                            </Menu>
                                        </>
                                    )
                                } else {
                                    if (
                                        item.icon !== undefined &&
                                        item.icon !== '' &&
                                        SecureCheck(item.roles)
                                    ) {
                                        return (
                                            <>
                                                <Menu
                                                    key={'Menu' + index}
                                                    /*   iconShape="circle"*/
                                                >
                                                    <SubMenu
                                                        key={'SubMenu' + index}
                                                        defaultOpen={mappingSubmenu(
                                                            IntlMessageValue(
                                                                props.value,
                                                                item.header,
                                                                Store
                                                            ),
                                                            active
                                                        )}
                                                        title={IntlMessageValue(
                                                            props.value,
                                                            item.header,
                                                            Store
                                                        )}
                                                    >
                                                        <ItemMenu
                                                            header={item.header}
                                                            key={'ItemMenu' + index}
                                                            active={active}
                                                            setActive={setActive}
                                                            items={item.menuitem}
                                                            value={props.value}
                                                            Store={Store}
                                                        />
                                                    </SubMenu>
                                                </Menu>
                                            </>
                                        )
                                    }
                                }
                                return null
                            })}
                        </div>
                        <div className="sidebarFooter sidebar-btn-wrapper">
                            <a
                                href={
                                    process.env.REACT_APP_LOGO_IMG_WUM_LINK !==
                                    undefined
                                        ? process.env.REACT_APP_LOGO_IMG_WUM_LINK
                                        : 'https://wum-solution.de'
                                }
                                target="_blank"
                                className="sidebar-btn"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={process.env.REACT_APP_LOGO_IMG_WUM}
                                    style={{maxHeight: '60px'}}
                                    alt={'Logo'}
                        />
                    </a>
                </div>
            </SidebarT>
        </>
    )
}
export default Sidebar

export const ShowDialog = (Store: any) => {
    let eles = document.getElementById('dialog') as HTMLDivElement
    eles.style.display = 'block'
    Gallery('', '', '', Store, '', null, null, null, false)
}
