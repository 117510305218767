import '../css/Software_Support.css'
import React, {useState} from "react";
import {useSelector} from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {collapse} from "../../../ticketsystem/modules/new/Pages/SingleTask";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import MIcon from "../../../../@WUM/core/Elements/MIcon";
import {ApiURL, DynamikOffCanvas, Editor, IntlMessage, IntlMessageValue} from "../../../../@WUM/core/Elements";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import {ApiRoot} from "../../../../@WUM/core/RestFullApi/api";
import {getToken, notify_save, setNavi} from "../../../../@WUM/core/Function/index";
import {ResponseEmail, ResponseMailbox} from "../API/response/support_software";
import {SpinnerJSX} from "../../../ticketsystem/modules/components/Spinner";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {ResponseDeparmtent} from "../../organisation/API/response/support_software";
import {FilterResponse} from "../../../ticketsystem/modules/new/Pages/MyProjects";
import InputGroup from "react-bootstrap/InputGroup";
import SunEditor from "suneditor-react";
import SunEditorConfig from "../../../ticketsystem/modules/components/SuneditorConfig";
import {init2} from "../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";

const EmailSettings = (props:{value:any}) =>{
    const Store = useSelector((state: any) => state)
    const [fetchData, setFetchData] = useState(0);
    setNavi({
        route:'/email/settings/overview/'
    })
    const [emailID, setEmailID] = useState(0)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [data, setData] = useState({
        "id": 13,
        "mandantenID": 1,
        "imapUser": "m.ugurcu@hotmail.de",
        "imapPassword": "test",
        "imapHost": "test@hotmail.de",
        "imapPort": 465,
        "imapSSL": null,
        "smtpUser": "m.ugurcu@hotmail.de",
        "smtpPassword": "test",
        "smtpHost": "test@hotmail.de",
        "smtpPort": 965,
        "smtpSSL": null,
        "signatur": null,
        "header": null,
        "footer": null,
        "state": null,
        "updated_at": null,
        "created_at": null,
        "defaultDid": null,
        "deparments": []
    })
    const Email = ResponseEmail(emailID,fetchData,setData);
    const Mailbox = ResponseMailbox('1',fetchData)


    let DepartmentHoldder= [];
    const Department = ResponseDeparmtent('1');
    let DepartmentNumber = 0;
    if (Department !== null && Department !== undefined && typeof Department !== "boolean" && Department.length !== 0) {
        for (let c = 0; c < Department.length; c++) {

            DepartmentNumber++;
            DepartmentHoldder.push(
                <>
                    <option value={Department[c].id}>{
                        IntlMessageValue('de',  Department[c].name, Store)
                    }</option>
                </>
            )
        }
    }

    const collapseGeneralSettings = (e:any,id:any,arrowShow:any=true,noneShow:any = [],withLabel:any=false,setFetchData?:any) => {

        let Collapse = document.getElementById(id) as HTMLDivElement;
        if (Collapse.classList.contains("d-none") === true) {
            Collapse.classList.remove("d-none");
            Collapse.classList.add("d-block");

            for(let ns=0;ns<=noneShow.length;ns++) {
                let CollapseClose = document.getElementById(noneShow[ns]) as HTMLDivElement;
                if(CollapseClose !== null) {
                    CollapseClose.classList.add("d-none");
                    CollapseClose.classList.remove("d-block");
                }



            }
        }
    }

    const editEmailHeaderFooterSettings = (e:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            mandantenID:formdata.get('mandantenID'),
            header: formdata.get('header'),
            footer:formdata.get('footer')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/email/save/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result))
            .catch((error) => errorForm(error))
    }

    const editSignaturSettings = (e:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            mandantenID:formdata.get('mandantenID'),
            signatur: formdata.get('signatur')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/email/save/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result))
            .catch((error) => errorForm(error))
    }

    const editEmailSettings = (e:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            imapUser: formdata.get('imapUser'),
            imapPassword: formdata.get('imapPassword'),
            imapHost: formdata.get('imapHost'),
            imapPort: formdata.get('imapPort'),
            imapSSL: formdata.get('imapSSL'),
            smtpUser: formdata.get('smtpUser'),
            smtpPassword: formdata.get('smtpPassword'),
            smtpHost: formdata.get('smtpHost'),
            smtpPort: formdata.get('smtpPort'),
            signatur: formdata.get('signatur'),
            header: formdata.get('header'),
            footer:formdata.get('footer'),
            smtpSSL: formdata.get('smtpSSL'),
            mandantenID:formdata.get('mandantenID')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/email/save/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result))
            .catch((error) => errorForm(error))
    }

    const SetDepartment = (e:any,eid:any) => {
        e.preventDefault()
        let data = e.currentTarget
        let DepartmentID = document.getElementById('Department') as HTMLSelectElement;
        if(DepartmentID !== null) {
            let did = DepartmentID.value;


        var raw = ''

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/email/department/save/'+eid+'/'+did+'/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result))
            .catch((error) => errorForm(error))
        }
        DepartmentID.value = ''
    }

    const SetImport = (e:any,eid:any,did:any) => {
        e.preventDefault()
        let data = e.currentTarget



            var raw = ''

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot()+'yoptimize/email/default/department/save/'+eid+'/'+did+'/', requestOptions)
                .then((response) => response.text())
                .then((result) => successForm(result))
                .catch((error) => errorForm(error))
        }


    const successForm = (result:any) => {
        setFetchData(Date.now())
        notify_save()


    }

    const errorForm = (error:any)=> {
        console.log(error)
    }

    if (typeof Email !== "boolean" && Email !== undefined) {


        const ListDeparment = [];
    if(Email.deparments !== undefined) {
        for(let ed=0;ed<Email.deparments.length;ed++){

            ListDeparment.push(
                <>
                    <tr>
                        <td>
                            {
                                IntlMessageValue('de',  Email.deparments[ed].name, Store)
                            }
                        </td>
                        <td>
                              <span className="material-icons mr-2 text-warning " style={{color: 'yellow',cursor:'default'}}>
                                star
                            </span>
                        </td>
                        <td>

                            {(Email.defaultDid === Email.deparments[ed].id ?
                                    <span className="material-icons mr-2 text-warning " style={{color: 'yellow',cursor:'default'}}>
                                star
                            </span> :
                                <span className="material-symbols-outlined" style={{cursor:'pointer'}} onClick={(e) => SetImport(e, emailID,Email.deparments[ed].id)}>
                                star
                            </span>
                                )}

                        </td>
                    </tr>
                </>
            )
        }
    }


        const SetEmailView = (id: any) => {
            setEmailID(id)
            collapseGeneralSettings(id, 'Settings',false,['Mailbox','Template','Signatur'])
        }


        let MailboxHolder = [];

        for(let m=0; typeof Mailbox !== "boolean" && m < Mailbox?.length; m++) {
            MailboxHolder.push(
                <tr>
                    <td>{Mailbox[m].imapHost}</td>
                    <td>{Mailbox[m].imapUser}</td>
                    <td>{Mailbox[m].imapPort}</td>
                    <td>{Mailbox[m].imapSSL}</td>
                    <td>{Mailbox[m].smtpHost}</td>
                    <td>{Mailbox[m].smtpUser}</td>
                    <td>{Mailbox[m].smtpPort}</td>
                    <td>{Mailbox[m].smtpSSL}</td>
                    <td><Button variant="primary" onClick={(e)=>SetEmailView(Mailbox[m].id)}>Bearbeiten</Button></td>
                </tr>
            )
        }

        const handlesubmit = (e:any) => {
            e.preventDefault()
            let data = e.currentTarget

            var formdata = new FormData(data)
            var raw = JSON.stringify({
                id: formdata.get('id'),
                imapUser: formdata.get('imapUser'),
                imapPassword: formdata.get('imapPassword'),
                imapHost: formdata.get('imapHost'),
                imapPort: formdata.get('imapPort'),
                imapSSL: formdata.get('imapSSL'),
                smtpUser: formdata.get('smtpUser'),
                smtpPassword: formdata.get('smtpPassword'),
                smtpHost: formdata.get('smtpHost'),
                smtpPort: formdata.get('smtpPort'),
                smtpSSL: formdata.get('smtpSSL')
            })

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'Bearer ' +
                        JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw
            }

            fetch(ApiRoot()+'yoptimize/email/save/', requestOptions)
                .then((response) => response.text())
                .then((result) => successForm(result))
                .catch((error) => errorForm(error))
        };

        const CloseDynmaikModal = () => {
            handleClose();
        }
        return (
            <>
                <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
                    <Row>
                        <Col xs={2} md={2} xl={2} xxl={2}>
                            <h3 id={'headline'}>{'Email Settings'}</h3>
                            <h4 onClick={(e) => collapse(e, 'actions')}>{'Actions'}
                                <span id={'actions_arrow'} className="material-icons" style={{verticalAlign: 'bottom'}}>
                            expand_less
                        </span>
                        </h4>
                        <div id={'actions'} className={'d-block'}>
                            <Navbar className="bg-body-transparent">
                                <Container>
                                    <Navbar.Brand style={{fontSize: '16px',cursor:'pointer'}} onClick={(e) => collapseGeneralSettings(e, 'Mailbox',false,['Settings'])} >
                                        <MIcon variant={'fill'} className={'mr-3'}  style={{verticalAlign: 'middle'}}>
                                            mail
                                        </MIcon>
                                        Mailbox
                                    </Navbar.Brand>
                                </Container>
                            </Navbar>
                        </div>
                    </Col>
                    <Col>
                        <div id={'Mailbox'} className={'d-block'}>
                            <Card>
                                <Card.Body>

                                        <h4 className={'mt-3 mb-3'}>Mailboxes</h4>
                                        <Table striped bordered hover>
                                            <thead>
                                            <tr>
                                                <th>imapHost</th>
                                                <th>imapUser</th>
                                                <th>imapPort</th>
                                                <th>imapSSL</th>
                                                <th>smtpHost</th>
                                                <th>smtpUser</th>
                                                <th>smtpPort</th>
                                                <th>smtpSSL</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {MailboxHolder}
                                            </tbody>
                                        </Table>
                                        <DynamikOffCanvas
                                            openButtonLabel={<><span className="material-icons" style={{verticalAlign: 'bottom'}}>
                    add
                    </span>
                                                {IntlMessageValue('de', 'new.email', Store)}</>}
                                            saveButtonLabel={IntlMessageValue('de', 'common.save', Store)}
                                            closeButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                                            title={IntlMessageValue('de', 'new.email', Store)}
                                            ClassOpenButton={'float-end'}
                                            ClassSaveButton={'float-end'}
                                            body={<>
                                            <Tabs
                                                defaultActiveKey="imap"
                                                id="uncontrolled-tab-example"
                                                className="mb-3"
                                            >
                                                <Tab eventKey="imap" title="Imap">
                                                    <Card>
                                                        <Card.Body>

                                                            <h4>E-Mail-Eingangsserver (IMAP)</h4>
                                                            <Form.Control type="hidden" name={'id'}
                                                                          defaultValue={'0'}/>
                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Benutzername:</Form.Label>
                                                                <Form.Control type="text" name={'imapUser'}/>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Passwort:</Form.Label>
                                                                <Form.Control type="password" name={'imapPassword'}
                                                                              autoComplete={"false"}/>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Hostname</Form.Label>
                                                                <Form.Control type="text" name={'imapHost'}/>
                                                            </Form.Group>
                                                            <Row>
                                                                <Col>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Port:</Form.Label>
                                                                        <Form.Control type="text" name={'imapPort'}/>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={2} md={2} xl={2} xxl={2}>
                                                                    <Form.Group className="mb-3"
                                                                                controlId="exampleForm.ControlInput1">
                                                                        <Form.Label></Form.Label>
                                                                        <Form.Check
                                                                            style={{marginTop: '15px'}}
                                                                            label={'TLS/SSL verwenden'}
                                                                            name={'imapSSL'}
                                                                            type={'checkbox'}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>


                                                        </Card.Body>
                                                    </Card>
                                                </Tab>
                                                <Tab eventKey="smtp" title="Smtp">
                                                    <Card>
                                                        <Card.Body>

                                                            <h4>E-Mail-Ausgangsserver (SMTP)</h4>
                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Benutzername:</Form.Label>
                                                                <Form.Control type="text" name={'smtpUser'}/>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Passwort:</Form.Label>
                                                                <Form.Control type="password" name={'smtpPassword'}/>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>Hostname:</Form.Label>
                                                                <Form.Control type="text" name={'smtpHost'}/>
                                                            </Form.Group>

                                                            <Row>
                                                                <Col>
                                                                    <Form.Group className="mb-3"
                                                                                controlId="exampleForm.ControlInput1">
                                                                        <Form.Label>Port:</Form.Label>
                                                                        <Form.Control type="text" name={'smtpPort'}/>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={2} md={2} xl={2} xxl={2}>
                                                                    <Form.Group className="mb-3"
                                                                                controlId="exampleForm.ControlInput1">
                                                                        <Form.Label></Form.Label>
                                                                        <Form.Check
                                                                            style={{marginTop: '15px'}}
                                                                            label={'TLS/SSL verwenden'}
                                                                            name={'smtpSSL'}
                                                                            type={'checkbox'}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>

                                                        </Card.Body>
                                                    </Card>
                                                </Tab>
                                            </Tabs>
                                            </>}
                                            handlesubmit={handlesubmit}
                                            handleShow={handleShow}
                                            show={show}
                                            id={'TaskNew'}
                                            setShow={setShow}
                                            handleClose={CloseDynmaikModal}
                                        />

                                </Card.Body>
                            </Card>
                        </div>
                        <div id={'Settings'} className={'d-none'}>
                            <Form
                                id={'form001'}
                                className={'formControl'}
                                noValidate autoComplete="none"
                                onSubmit={(e) =>
                                    editEmailSettings(e)
                                }
                            >
                                <Tabs
                                    defaultActiveKey="imap"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="imap" title="Imap">
                                        <Card>
                                            <Card.Body>

                                                <h4>E-Mail-Eingangsserver (IMAP)</h4>
                                                <Form.Control type="hidden" name={'id'}
                                                              defaultValue={Email.id}/>
                                                <Form.Control type="hidden" name={'mandantenID'}
                                                              defaultValue={Email.mandantenID}/>
                                                <Form.Group className="mb-3"  >
                                                    <Form.Label>Benutzername:</Form.Label>
                                                    <Form.Control type="text" name={'imapUser'}
                                                                  defaultValue={Email.imapUser}/>
                                                </Form.Group>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Passwort:</Form.Label>
                                                    <Form.Control type="password" name={'imapPassword'}
                                                                  autoComplete={"false"}
                                                                  defaultValue={Email.imapPassword}/>
                                                </Form.Group>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Hostname</Form.Label>
                                                    <Form.Control type="text" name={'imapHost'}
                                                                  defaultValue={Email.imapHost}/>
                                                </Form.Group>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Port:</Form.Label>
                                                            <Form.Control type="text" name={'imapPort'}
                                                                          defaultValue={Email.imapPort}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={2} md={2} xl={2} xxl={2}>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label></Form.Label>
                                                            <Form.Check
                                                                style={{marginTop: '15px'}}
                                                                label={'TLS/SSL verwenden'}
                                                                defaultChecked={(Email.imapSSL === '1' ? true : false)}
                                                                name={'imapSSL'}
                                                                type={'checkbox'}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>


                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey="smtp" title="Smtp">
                                        <Card>
                                            <Card.Body>

                                                    <h4>E-Mail-Ausgangsserver (SMTP)</h4>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Benutzername:</Form.Label>
                                                        <Form.Control type="text" name={'smtpUser'}
                                                                      defaultValue={ Email.smtpUser}/>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Passwort:</Form.Label>
                                                        <Form.Control type="password" name={'smtpPassword'}
                                                                      defaultValue={Email.smtpPassword}/>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Hostname:</Form.Label>
                                                        <Form.Control type="text" name={'smtpHost'}
                                                                      defaultValue={Email.smtpHost}/>
                                                    </Form.Group>

                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="mb-3" >
                                                                <Form.Label>Port:</Form.Label>
                                                                <Form.Control type="text" name={'smtpPort'}
                                                                              defaultValue={Email.smtpPort}/>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={2} md={2} xl={2} xxl={2}>
                                                            <Form.Group className="mb-3" >
                                                                <Form.Label></Form.Label>
                                                                <Form.Check
                                                                    style={{marginTop: '15px'}}
                                                                    label={'TLS/SSL verwenden'}
                                                                    defaultChecked={(Email.smtpSSL === '1' ? true : false)}
                                                                    name={'smtpSSL'}
                                                                    type={'checkbox'}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey="template" title="Template">
                                        <Card>
                                            <Card.Body>

                                                    <h4 className={'mt-3 mb-3'}>Email Header</h4>
                                                    <Editor lang={'de'} config={"formatting"} height={'450px'}
                                                            mode={"classic"}
                                                            name={'header'}
                                                            defaultValue={data.header !== null ? data.header : ''}/>
                                                    <h4 className={'mt-3 mb-3'}>Email Footer</h4>
                                                    <Editor lang={'de'} config={"formatting"} height={'450px'}
                                                            mode={"classic"}
                                                            name={'footer'}
                                                            defaultValue={data.footer !== null ? data.footer : ''}/>

                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey="signatur" title="Signatur">
                                        <Card>
                                            <Card.Body>
                                                    <h4>Email Signatur</h4>
                                                    <Editor lang={'de'} config={"formatting"} height={'450px'}
                                                            mode={"classic"}
                                                            name={'signatur'}
                                                            defaultValue={data.signatur !== null ? data.signatur : ''}/>

                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey="department" title={IntlMessageValue('de', 'common.department', Store)}>
                                        <Card>
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <Form.Select aria-label="Default select example" id={'Department'}>
                                                            <option label={IntlMessageValue('de', 'common.filter.all', Store)}/>
                                                            {DepartmentHoldder}
                                                            <option value={'null'}>Nicht zugewiesen</option>
                                                        </Form.Select>
                                                    </Col>
                                                    <Col>
                                                        <Button variant="primary" onClick={(e)=>SetDepartment(e,Email.id)}>Hinzufügen</Button>
                                                    </Col>
                                                </Row>

                                                <Table striped bordered hover className={'mt-3'}>
                                                    <thead>
                                                    <tr>
                                                        <th>{IntlMessageValue('de', 'common.department', Store)}</th>
                                                        <th>{IntlMessageValue('de', 'table.email.send', Store)}</th>
                                                        <th>{IntlMessageValue('de', 'table.email.import', Store)}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {ListDeparment}
                                                    </tbody>
                                                </Table>

                                    </Card.Body>
                                </Card>
                                    </Tab>

                                </Tabs>
                                <button type="submit" id={'AttachDocumentSendButton'}
                                        className="btn btn-primary float-end mt-3">{IntlMessageValue('de', 'common.save', Store)}
                                </button>
                                <br style={{clear:'both'}}/>
                            </Form>
                        </div>
                    </Col>
                    </Row>

                </div>
            </>
        )

    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'}/>
            </>
        )
    }
}

export default EmailSettings;
