import React from "react";
import EmailSettings from './Pages/overview'
import {CompanyConfig} from "../company";

export const EmailSettingConfig = (value:any) => {

    return [
        {
            path: '/email/settings/overview/:page',
            elements: <EmailSettings value={value} />,
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']
        }
    ]
}

export const EmailSettingMenuConfig = (value:any) => {
    return [
            {
                name: 'common.email_and_notification',
                route: '/email/settings/overview/1',
                icon: 'mail',
                roles: ['superadmin', 'admin',
                    'ticketsystem',
                    'worktime',
                    'teamtakt']
            }

    ]
}

export const EmailSetting = () => [
    {
        title: 'Support Ticket',
        href: '/support/software/overview/1',
        undertitle: 'Software-Support',
        icon: 'manage_accounts',
        text: 'Software-Support',
        roles: ['superadmin', 'admin',
            'ticketsystem',
            'worktime',
            'teamtakt']
    }
]
