import {useEffect, useState} from "react";
import {error} from "../../../../../@WUM/core/Elements/index";
import {support_softwareApiType} from "../request/SupportSoftwareApi.interface";
import {email} from "../request/support_software";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";

export const ResponseEmail = (mandanID:any,fetchData:any,setData:any) => {
    const [posts, setPosts] = useState<support_softwareApiType>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
            ;(async () => {
                    await email
                        .getPosts(mandanID)
                        .then((data) => {
                            checkLogin(data)
                            setPosts(data)
                            setData(data)
                            //console.log(data);
                        })
                        .catch((err) => {
                            error(err)
                            setIsError(true)
                        })
                })()

    }, [mandanID,fetchData])

    return isError ? isError : posts
}

export const ResponseMailbox = (mandanID:any,fetchData:any) => {
    const [posts, setPosts] = useState<support_softwareApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await email
                .getPostsMailbox(mandanID)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()

    }, [mandanID,fetchData])

    return isError ? isError : posts
}