import '../css/ticketsystem2.css'
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {setNavi} from "../../../@WUM/core/Function/index";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import {Chart} from "react-google-charts";
import {ResponseDashbaordTask} from "../API/response/ticketsystem2";
import {Link, useNavigate} from "react-router-dom";
import {colorSwitcher} from "../../ticketsystem/modules/new/Pages/MyTask";
import {IntlMessageValue} from "../../../@WUM/core/component/const/IntlMessage";
import Table from 'react-bootstrap/Table';
import {dateFormat, IntlMessage} from "../../../@WUM/core/Elements";
const Ticketsystem2Overview = (value:any) =>{
    setNavi({
        route:'/ticketsystem/overview/1'
    })
    const [fetchData, setFetchData] = useState(0);
    const Store = useSelector((state: any) => state)
    const Tasks = ResponseDashbaordTask(0)
    const data = [];
    const navigate = useNavigate();
    if (Tasks !== true && typeof Tasks !== "boolean" && Tasks !== undefined) {
        data.push(["Task", "Hours per Day"]);
        data.push([IntlMessageValue('de', 'inbox.priority.Lowest', Store), Tasks.EmployeOverciew[0].prio.lowest]);
        data.push([IntlMessageValue('de', 'inbox.priority.Low', Store), Tasks.EmployeOverciew[0].prio.low]);
        data.push([IntlMessageValue('de', 'inbox.priority.Medium', Store), Tasks.EmployeOverciew[0].prio.medium]);
        data.push([IntlMessageValue('de', 'inbox.priority.High', Store), Tasks.EmployeOverciew[0].prio.hight]);
        data.push([IntlMessageValue('de', 'inbox.priority.Urgent', Store), Tasks.EmployeOverciew[0].prio.urgent]);
    }

    const options = {
        title: IntlMessageValue('de', 'inbox.dashboard.ticket.priority', Store),
        hAxis: {title: "Year", titleTextStyle: {color: "#333"}},
        vAxis: {
            minValue: 0, maxValue: 40000, gridlines: {
                count: 0
            }
        },
        backgroundColor:'transparent',
        pieHole: 0.4,
        slices: {
            0: { color: "lightgreen" },
            1: { color: "green" },
            2: { color: "orange" },
            3: { color: "red" },
            4: { color: "darkred" }
        },
        chartArea: {width: "100%", height: "80%"},
        legend: { position: 'bottom', alignment: 'center' },
    };

    let Piedata = [ ];

    const optionsPie = {
        title: IntlMessageValue('de', 'inbox.dashboard.ticket.status', Store),
        hAxis: {title: "Year", titleTextStyle: {color: "#333"}},
        vAxis: {
            minValue: 0, maxValue: 40000, gridlines: {
                count: 0
            }
        },
        backgroundColor:'transparent',
        pieHole: 0.4,
        chartArea: {width: "100%", height: "80%"},
        legend: { position: 'bottom', alignment: 'center' },
    };

    if (Tasks !== true && typeof Tasks !== "boolean"  && Tasks !== undefined) {
        Piedata.push(["Task", "Hours per Day"]);
        Piedata.push([IntlMessageValue('de', 'inbox.status.Open', Store), Tasks.EmployeOverciew[0].state.open]);
        Piedata.push([IntlMessageValue('de', 'inbox.status.In Progress', Store), Tasks.EmployeOverciew[0].state.inProgress]);
        Piedata.push([IntlMessageValue('de', 'inbox.status.Pending', Store), Tasks.EmployeOverciew[0].state.pending]);
        Piedata.push([IntlMessageValue('de', 'inbox.status.Resolve', Store), Tasks.EmployeOverciew[0].state.close]);
    }

    let Piesdata = [ ];

    const optionsPies = {
        title: IntlMessageValue('de', 'inbox.dashboard.ticket.type', Store),
        hAxis: {title: "Year", titleTextStyle: {color: "#333"}},
        vAxis: {
            minValue: 0, maxValue: 40000, gridlines: {
                count: 0
            }
        },
        backgroundColor:'transparent',
        pieHole: 0.4,
        chartArea: {width: "100%", height: "80%"},
        legend: { position: 'bottom', alignment: 'center' },
    };

    if (Tasks !== true && typeof Tasks !== "boolean"  && Tasks !== undefined) {
        Piesdata.push(["Task", "Hours per Day"]);
        Piesdata.push([IntlMessageValue('de', 't2.Bug', Store), Tasks.EmployeOverciew[0].type.bug]);
        Piesdata.push([IntlMessageValue('de', 't2.Fehlende Funktion', Store), Tasks.EmployeOverciew[0].type.missing_function]);
        Piesdata.push([IntlMessageValue('de', 't2.Verbesserung', Store), Tasks.EmployeOverciew[0].type.improvement]);
        Piesdata.push([IntlMessageValue('de', 't2.Individuelle Anpassung', Store), Tasks.EmployeOverciew[0].type.individual_customization]);
    }
    let colorset = [
        {color:'#2EA02E'},
        {color:'#2EA02E'},
        {color:'#2EA02E'},
        {color:'#ff8800'},
        {color:'#B71234'},
        {color:'#B71234'}
    ]

    let colorsetState = [
        {color:'#2EA02E'},
        {color:'#001ca4'},
        {color:'#fcfc40'},
        {color:'#ff8800'},
        {color:'#f82c00'},
        {color:'#14b814'}
    ]

    if (Tasks !== true && typeof Tasks !== "boolean"  && Tasks !== undefined) {

        let RecentHolder = [];

        for(let re=0;re<Tasks.RecentTask.length;re++) {
            let Data = Tasks.RecentTask[re]
            RecentHolder.push(
                <>
                    <tr onDoubleClick={() => navigate('/ticket/single/ticketsystem/' + Data.id)} >
                        <td><p className={'tableHeight'}>{Data.plugin}</p></td>
                        <td><p className={'tableHeight'}>{Data.regard}</p></td>
                        <td> <span className="badge " style={{
                            background: (Data.priority !== null ? colorset[Data.priority].color : colorset[0].color),
                            color: (colorSwitcher((Data.priority !== null ? colorset[Data.priority].color : colorset[0].color), false) ? 'black' : 'white')
                        }}>
                           {(Data.priority !== null ? IntlMessageValue('de', 'inbox.priority.' + Data.ticket_prioritaeten.name, Store) : 'Low')}
                        </span></td>
                        <td>
                            <span className="badge rounded-pill" style={{
                                background: (Data.status_id !== null ? colorsetState[Data.status_id].color : colorsetState[0].color),
                                color: (colorSwitcher((Data.status_id !== null ? colorsetState[Data.status_id].color : colorsetState[0].color), false) ? 'black' : 'white')
                            }}>
                            {IntlMessageValue('de', 'inbox.status.' + Data.status.state, Store)}
                        </span></td>
                        <td>{(Data.support_type_id !== null ? <><IntlMessage messageId={"dashboard.t3." + Data.ticket_type.type}
                                                                                                     Store={Store}/></> : '')}</td>
                        <td>{
                            dateFormat(
                                Data.created_at,
                                localStorage.getItem('locale'),
                                'us',
                                true,
                                'onlyDate',
                                Store
                            )
                        }</td>
                    </tr>
                    {/* <Col className={'mb-3 col-4'}
                         onDoubleClick={() => navigate('/ticket/single/ticketsystem/' + Data.id)}>
                        <Row className={'mr-3 bg-white pl-3 pb-3 pt-3 border rounded pr-0'}>
                            <Col className={'col-5 mb-3'}
                                 style={{borderLeft: '3px solid ' + colorset[Data.priority].color}}>
                            <p>{Data.created_at}</p><Badge bg="secondary" className={'mr-2'}
                                                               pill={true}>{Data.status.state}</Badge>
                                <span className="badge " style={{
                                    background: (Data.priority !== null ? colorset[Data.priority].color : colorset[0].color),
                                    color: (colorSwitcher((Data.priority !== null ? colorset[Data.priority].color : colorset[0].color), false) ? 'black' : 'white')
                                }}>
                            {(Data.priority !== null ? Data.ticket_prioritaeten.name : 'Low')}
                        </span>
                            </Col>
                            <Col className={'mb-3'}><p><strong>{Data.plugin}</strong></p><p>{Data.regard}</p>
                            </Col>

                        </Row>
                    </Col>*/}
                </>
            )
        }

        return (<>

            <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
                <h3 className={'mb-3'}>Dashbaord</h3>
                <Row>
                    <Col>
                        <Card>
                        <Card.Body className={'parent'}>
                            <p className={'div1'}><IntlMessage messageId={'inbox.dashbaord.ticket.all'} Store={Store}/></p>
                            <p className={'div2'} >
                                <strong style={{fontSize:'20px'}}>{Tasks.EmployeOverciew[0].state.all}</strong>
                            </p>
                            <p className={'div3 text-center'}>
                            <span className="material-icons "  style={{fontSize: '60px',border:'1px solid #D4EFF8',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                        confirmation_number
                                    </span>
                            </p>

                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                            <Card.Body className={'parent'}>
                                <p className={'div1'}><IntlMessage messageId={'dashboard.box.overdue.open_tickets'} Store={Store}/></p>
                                <p className={'div2'} >
                                    <strong style={{fontSize:'20px'}}>{Tasks.EmployeOverciew[0].state.open}
                                        <Link state={{searchFilter: 'state', searchParam: 1}}
                                              to={'/ticket/ticketsystem/1'}
                                              style={{textDecoration: 'none', color: 'black'}}>
                                        <span className="material-icons-outlined" style={{marginLeft:'10px',verticalAlign:'middle',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                            visibility
                                        </span>
                                        </Link>
                                    </strong>
                                </p>
                                <p className={'div3 text-center'}>
                            <span className="material-icons "  style={{fontSize: '60px',border:'1px solid #D4EFF8',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                        folder_open
                                    </span>
                                </p>

                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body className={'parent'}>
                            <p className={'div1'}><IntlMessage messageId={'dashboard.box.overdue.in_progress_tickets'}
                                                               Store={Store}/></p>
                            <p className={'div2'}>
                                <strong style={{fontSize: '20px'}}>{Tasks.EmployeOverciew[0].state.inProgress}
                                    <Link state={{searchFilter: 'state', searchParam: 2}}
                                          to={'/ticket/ticketsystem/1'}
                                          style={{textDecoration: 'none', color: 'black'}}>
                                        <span className="material-icons-outlined" style={{
                                            marginLeft: '10px',
                                            verticalAlign: 'middle',
                                            borderRadius: '15px',
                                            color: '#23AAE1',
                                            background: '#D4EFF8'
                                        }}>
                                            visibility
                                        </span>
                                    </Link>
                                </strong>
                            </p>
                            <p className={'div3 text-center'}>
                            <span className="material-symbols-outlined " style={{
                                fontSize: '60px',
                                border: '1px solid #D4EFF8',
                                borderRadius: '15px',
                                color: '#23AAE1',
                                background: '#D4EFF8'
                            }}>
                                        folder_managed
                                    </span>
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
                    <Col>
                    <Card>
                        <Card.Body className={'parent'}>
                            <p className={'div1'}><IntlMessage messageId={'dashboard.box.overdue.pending_tickets'} Store={Store}/></p>
                            <p className={'div2'} >
                                <strong style={{fontSize:'20px'}}>{Tasks.EmployeOverciew[0].state.pending}
                                    <Link state={{searchFilter: 'state', searchParam: 3}}
                                          to={'/ticket/ticketsystem/1'}
                                          style={{textDecoration: 'none', color: 'black'}}>
                                        <span className="material-icons-outlined" style={{marginLeft:'10px',verticalAlign:'middle',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                            visibility
                                        </span>
                                    </Link>
                                </strong>
                            </p>
                            <p className={'div3 text-center'}>
                            <span className="material-icons "  style={{fontSize: '60px',border:'1px solid #D4EFF8',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                        folder_shared
                                    </span>
                            </p>

                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body className={'parent'} >
                            <p className={'div1'}><IntlMessage messageId={'dashboard.box.overdue.resolve'} Store={Store}/></p>
                            <p className={'div2'} >
                                <strong style={{fontSize:'20px'}}>{Tasks.EmployeOverciew[0].state.close}
                                    <Link state={{searchFilter: 'state', searchParam: 4}}
                                          to={'/ticket/ticketsystem/1'}
                                          style={{textDecoration: 'none', color: 'black'}}>
                                        <span className="material-icons-outlined" style={{marginLeft:'10px',verticalAlign:'middle',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                            visibility
                                        </span>
                                    </Link>
                                </strong>
                            </p>
                            <p className={'div3 text-center'}>
                            <span className="material-icons "  style={{fontSize: '60px',border:'1px solid #D4EFF8',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                        fact_check
                                    </span>
                            </p>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
                <Row style={{marginTop: '2em',  marginRight: '0px'}}>
                    <Col>
                        <Card>
                            <Card.Body className={'parent'}>
                                <p className={'div1 p-0 m-0'}  style={{minWidth:'214px'}}><IntlMessage messageId={'dashboard.box.lowest.priority'}
                                                                   Store={Store}/>
                                    <strong style={{marginLeft:'15px',fontSize: '20px'}}>{Tasks.EmployeOverciew[0].prio.lowest}</strong>
                                </p><p className={'div3 p-0 m-0'}>

                                        <Link state={{searchFilter: 'priority', searchParam: 1}}
                                              to={'/ticket/ticketsystem/1'}
                                              style={{textDecoration: 'none', color: 'black'}}>
                                        <span className="material-icons-outlined" style={{marginLeft:'10px',verticalAlign:'-webkit-baseline-middle',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                            visibility
                                        </span>
                                        </Link>

                                </p>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body className={'parent'}>
                                <p className={'div1 p-0 m-0'}  style={{minWidth:'214px'}}><IntlMessage messageId={'dashboard.box.low.priority'} Store={Store}/>
                                    <strong style={{marginLeft:'15px',fontSize:'20px'}}>{Tasks.EmployeOverciew[0].prio.low} </strong>
                                </p><p className={'div3 p-0 m-0'}>  <Link state={{searchFilter: 'priority', searchParam: 2}}
                                                                    to={'/ticket/ticketsystem/1'}
                                                                    style={{textDecoration: 'none', color: 'black'}}>
                               <span className="material-icons-outlined" style={{marginLeft:'10px',verticalAlign: '-webkit-baseline-middle',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                            visibility
                                        </span>
                                    </Link>
                                </p>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body className={'parent'}>
                                <p className={'div1 p-0 m-0'}  style={{minWidth:'214px'}}><IntlMessage messageId={'dashboard.box.medium.priority'} Store={Store}/>
                                    <strong style={{marginLeft:'15px',fontSize:'20px'}}>{Tasks.EmployeOverciew[0].prio.medium}</strong>

                                </p><p className={'div3 p-0 m-0'}><Link state={{searchFilter: 'priority', searchParam: 3}}
                                                                                                                    to={'/ticket/ticketsystem/1'}
                                                                                                                    style={{textDecoration: 'none', color: 'black'}}>

                                    <span className="material-icons-outlined" style={{marginLeft:'10px',verticalAlign:'-webkit-baseline-middle',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                        visibility
                                    </span>

                                    </Link>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body className={'parent'}>
                                <p className={'div1 p-0 m-0'}  style={{minWidth:'214px'}}><IntlMessage messageId={'dashboard.box.high.priority'} Store={Store}/>
                                    <strong style={{marginLeft:'15px',fontSize:'20px'}}>{Tasks.EmployeOverciew[0].prio.hight}</strong>
                                </p><p className={'div3 p-0 m-0'}><Link state={{searchFilter: 'priority', searchParam: 4}}
                                                                                                                  to={'/ticket/ticketsystem/1'}
                                                                                                                  style={{textDecoration: 'none', color: 'black'}}>

                                    <span className="material-icons-outlined" style={{marginLeft:'10px',verticalAlign:'-webkit-baseline-middle',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                        visibility
                                    </span>

                                    </Link>
                                </p>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body className={'parent'}>
                                <p className={'div1 p-0 m-0'} style={{minWidth:'214px'}}><IntlMessage messageId={'dashboard.box.urgent.priority'} Store={Store}/>
                                    <strong style={{marginLeft:'15px',fontSize:'20px'}}>{Tasks.EmployeOverciew[0].prio.urgent}</strong>
                                </p><p className={'div3 p-0 m-0'}>
                                        <Link state={{searchFilter: 'priority',searchParam:5}}
                                                                                                                    to={'/ticket/ticketsystem/1'}
                                                                                                                    style={{textDecoration: 'none', color: 'black'}}>

                                    <span className="material-icons-outlined" style={{marginLeft:'10px',verticalAlign:'middle',borderRadius:'15px',color:'#23AAE1',background:'#D4EFF8'}}>
                                        visibility
                                    </span>

                                    </Link>
                                </p>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            <Row style={{marginTop: '2em', marginLeft: '0px', marginRight: '0px'}}>
                <Col className={'col-6 bg-transparent p-0 '}>
                    <div className={' p-3 mb-3 rounded border bg-white'} style={{height:'100%'}}>
                        <h3><IntlMessage messageId={'dashboard.last6.tickets'} Store={Store}/></h3>
                        <Row className={'p-3'}>

                            <Table hover>
                                <tbody>
                                {RecentHolder}
                                </tbody>
                            </Table>
                        </Row>
                    </div>
                </Col>

                <Col className={'col-6 bg-transparent p-0'}>
                    <div className={'card p-3 ml-3'} style={{height:'100%'}}>
                        <Chart
                            chartType="PieChart"
                            data={data}
                            options={options}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                </Col>
            </Row>
                <Row style={{marginTop: '2em', marginLeft: '0px', marginRight: '0px'}}>
                    <Col className={'col-6 bg-transparent p-0'} style={{height:'100%'}}>
                        <div className={'card p-3'}>
                            <Chart
                                chartType="PieChart"
                                data={Piedata}
                                options={optionsPie}
                                width={"100%"}
                                height={"400px"}
                            />
                        </div>
                    </Col>
                <Col className={'col-6 bg-transparent p-0'}>
                    <div className={'card p-3 ml-3'} style={{height:'100%'}}>
                        <Chart
                            chartType="PieChart"
                            data={Piesdata}
                            options={optionsPies}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                </Col>
            </Row>


        </div>
    </>)

} else {
    return(
        <></>
    )
    }
}

export default Ticketsystem2Overview;
