import React from "react";
import Software_SupportOverview from './Pages/overview'
import Reports from "./Pages/reports";
import SoftwareSupportTicket from "./Pages/ticket";
import {ChatSupportMenuConfig, ChatSupportPagesConfig} from "./support_chat/modules";

export const Software_SupportConfig = (value:any) => {

    return [
        {
            path: '/support/software/overview/:page',
            elements: <Software_SupportOverview value={value} />,
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']
        },
        {
            path: '/support/ticket/software/:id',
            elements: <SoftwareSupportTicket value={value} />,
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']
        },
        {
            path: '/software/report/support/:page',
            elements: <Reports value={value} />,
            roles: ['superadmin', 'admin',
                'ticketsystem',
                'worktime',
                'teamtakt']
        },
        ...ChatSupportPagesConfig(value)
    ]
}

export const Software_SupportMenuConfig = (value:any) => {
    return [
        {
        header: 'Software-Support',
        icon: 'help',
        roles: ['superadmin', 'admin',
            'ticketsystem',
            'worktime',
            'teamtakt'],
        menuitem: [
            {
                name: 'Support Ticket',
                route: '/support/software/overview/1',
                icon: 'add_task',
                roles: ['superadmin', 'admin',
                    'ticketsystem',
                    'worktime',
                    'teamtakt']
            },
            ...ChatSupportMenuConfig(value)
            /*

            {
                name: 'Berichte',
                route: '/software/report/support/1',
                icon: 'query_stats',
                roles: ['superadmin', 'admin', 'merchant']
            }*/
            ]
        }
    ]
}

export const Software_Support = () => [
    {
        title: 'Support Ticket',
        href: '/support/software/overview/1',
        undertitle: 'Software-Support',
        icon: 'manage_accounts',
        text: 'Software-Support',
        roles: ['superadmin', 'admin',
            'ticketsystem',
            'worktime',
            'teamtakt']
    }
]
