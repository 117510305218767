import React from 'react'
import {
    notify_bucket,
    notify_bucket_error,
    notify_bucket_reload
} from './notification.service'
import { error, IntlMessageValue } from './../../Elements/index'

/**
 * Sende eine Datei zum Bucket von der AWS und speichert diese
 * @param e
 * @param append
 * @param onclick_context
 * @param src
 * @param type
 * @param id
 * @param url
 * @param mode
 * @param Store
 * @param namespace
 * @param addedRoute
 * @constructor
 */
const MediaImageUploadCDNUrl = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    append: any,
    onclick_context: any,
    src: any,
    type: boolean,
    id: any,
    url: any,
    mode: any = '',
    Store: any,
    namespace: any,
    addedRoute: any = '',
    reload?:any
) => {
    let eleNamespace = document.getElementById('namespace') as HTMLInputElement
    namespace = eleNamespace.value

    let eleImgsrc = document.getElementById('Imgsrc') as HTMLInputElement
    src = eleImgsrc.value

    let eleImgappend = document.getElementById('append') as HTMLInputElement
    append = eleImgappend.value

    let eleGaleriemode = document.getElementById(
        'Galeriemode'
    ) as HTMLInputElement
    let Galeriemode = eleGaleriemode.value

    let token = localStorage.getItem('token')
    let appendLoader = (e.currentTarget as HTMLElement).parentElement
    // @ts-ignore
    appendLoader.id = 'Bucketfile'
    let formfile = (e.currentTarget as HTMLInputElement).files
    var btn = document.createElement('DIV')
    btn.className = 'loaderBucket'
    btn.id = 'loader'
    // @ts-ignore
    document.getElementById('Bucketfile').appendChild(btn)
    var elem = document.getElementById('loader') as HTMLDivElement
    // @ts-ignore
    for (let x = 0; x < formfile.length; x++) {
        let allowedType

        if (mode === 'video') {
            allowedType = [
                { type: 'video/mp4', size: 80000000 },
                { type: 'video/webm', size: 80000000 }
            ]
        } else {
            allowedType = [
                { type: 'image/jpg', size: 5000000 },
                { type: 'image/jpeg', size: 5000000 },
                { type: 'image/webp', size: 5000000 },
                { type: 'image/png', size: 5000000 },
                { type: 'application/pdf', size: 5000000 },
                { type: 'video/mp4', size: 80000000 },
                { type: 'video/webm', size: 80000000 }
            ]
        }
        let errorCount = 0
        let errormsg = ''

        let checkType = 0
        let checkSize = 0
        if (allowedType !== undefined && allowedType.length > 0) {
            for (let type = 0; type < allowedType.length; type++) {
                // @ts-ignore
                if (allowedType[type].type === formfile[x].type) {
                    checkType = checkType + 1
                }
                // @ts-ignore
                if (formfile[0].size < allowedType[type].size) {
                    checkSize = checkSize + 1
                }
            }
        }

        if (checkType === 0 && checkSize === 0) {
            errorCount = errorCount + 1
            // @ts-ignore
            errormsg =
                IntlMessageValue('de', 'bucket.send.error.checkSize', Store) +
                // @ts-ignore
                (formfile[x].type === 'video/mp4' ? '50MB' : '5MB') +
                IntlMessageValue('de', 'bucket.send.error.type', Store) +
                // @ts-ignore
                formfile[x].type
            // @ts-ignore
            elem.remove()
        } else if (checkType === 0) {
            errorCount = errorCount + 1
            // @ts-ignore
            errormsg =
                IntlMessageValue('de', 'bucket.send.error.type', Store) +
                // @ts-ignore
                formfile[x].type +
                '.'
            // @ts-ignore
            elem.remove()
        } else if (checkSize === 0) {
            errorCount = errorCount + 1
            // @ts-ignore
            errormsg =
                IntlMessageValue('de', 'bucket.send.error.checkSize', Store) +
                // @ts-ignore
                (formfile[x].type === 'video/mp4' ? '50MB' : '5MB')
            // @ts-ignore
            elem.remove()
        }

        if (errorCount > 0) {
            notify_bucket_error(
                IntlMessageValue('de', 'bucket.send.error', Store),
                errormsg
            )
            return
        }

        let formDatas = new FormData()
        formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
        formDatas.append('context', onclick_context)
        // @ts-ignore
        formDatas.append(`file${0}`, formfile[x])
        var requestOptions = {
            method: 'POST',
            body: formDatas
        }

        let link
        if (process.env.REACT_APP_MODE === 'LOCAL') {
            link = process.env.REACT_APP_CDN_API_URL_LOCAL
        } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
            link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
        } else if (process.env.REACT_APP_MODE === 'LIVE') {
            link = process.env.REACT_APP_CDN_API_URL_LIVE
        }

        //let storeimage:any;

        fetch(link + 'upload/', requestOptions)
            .then((response) => response.text())
            .then((result) => {
                notify_bucket(Store)
                // @ts-ignore
                elem.remove()
                // @ts-ignore
                if (x === formfile.length - 1) {
                    let eletags = document.getElementById(
                        'tags'
                    ) as HTMLDivElement
                    eletags.innerHTML = ''
                    let eleimgHolder = document.getElementById(
                        'imgHolder'
                    ) as HTMLDivElement
                    eleimgHolder.innerHTML = ''
                    let eleSourceURL = document.getElementById('AttachDocumentUrl') as HTMLInputElement
                    eleSourceURL.value = JSON.parse(result).cdn_url
                    let eleSourceName = document.getElementById('AttachDocumentName') as HTMLInputElement
                    eleSourceName.value = JSON.parse(result).name
                    let eleSourceType = document.getElementById('AttachDocumentType') as HTMLInputElement
                    eleSourceType.value = JSON.parse(result).content_type
                    let eleSourceSendButton = document.getElementById('AttachDocumentSendButton') as HTMLInputElement
                    eleSourceSendButton.removeAttribute('disabled')
                }
                if(reload === true){
                    setTimeout(() => window.location.reload(), 3000);
                }
            })
            .catch((e) => {
                // @ts-ignore
                elem.remove()
                error(e)
            })
    }
}

export default MediaImageUploadCDNUrl
