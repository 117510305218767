import { MenuItem } from 'react-pro-sidebar'
import { Link } from 'react-router-dom'
import React from 'react'
import MenuIcon from './MenuIcon'
import { TooltipIcon, IntlMessageValue } from '../Elements/index'
import SecureCheck from '../RoleSecury'

const ItemMenu = (props: {
    items: any[]
    value: any
    Store: any
    header: any
    active: any
    setActive: any
}) => {
    let Store = props.Store
    let menuItem = []

    let URLLocation = window.location.pathname.split('/')

    if (props.items !== undefined) {
        for (let i = 0; i < props.items.length; i++) {
            let TargetLocation = props.items[i].route
            if (
                props.items[i].icon !== undefined &&
                props.items[i].icon !== '' &&
                SecureCheck(props.items[i].roles)
            ) {
                if (
                    props.items[i].onClick !== undefined &&
                    props.items[i].onClick !== false
                ) {
                    menuItem.push(
                        <>
                            <MenuItem
                                key={'MenuItem' + i}
                                className={'MenuItem'}
                                onClick={() => props.items[i].onClick(Store)}
                                icon={
                                    <MenuIcon
                                        icon={
                                            props.items[i].icon !== undefined
                                                ? props.items[i].icon
                                                : ''
                                        } newIcon={props.items[i].newIcon} variant={props.items[i].variant}
                                    />
                                }
                                suffix={
                                    process.env.REACT_APP_TOOLTIP_NAVI !==
                                    undefined ? (
                                        <>
                                            <TooltipIcon
                                                Store={Store}
                                                locale={props.value}
                                                content={props.header}
                                                name={props.items[i].name}
                                            />
                                        </>
                                    ) : (
                                        ''
                                    )
                                }
                            >
                                {IntlMessageValue(
                                    props.value,
                                    props.items[i].name,
                                    Store
                                )}
                            </MenuItem>
                        </>
                    )
                } else {
                    menuItem.push(
                        <>
                            <MenuItem
                                data-link={props.items[i].route}
                                key={'MenuItem' + i}
                                className={
                                    'MenuItem' +
                                    (checkLocation(
                                        URLLocation[1],
                                        TargetLocation
                                    ) === true
                                        ? ' activeSidebar'
                                        : ' deactiveSidebar')+ ' unLink'
                                }

                                component={
                                    <Link
                                        key={i}
                                        onClick={() =>
                                            props.setActive(
                                                props.items[i].route
                                            )
                                        }
                                        to={props.items[i].route}
                                    />
                                }
                                icon={
                                    <MenuIcon
                                        icon={
                                            props.items[i].icon !== undefined
                                                ? props.items[i].icon
                                                : ''
                                        } newIcon={props.items[i].newIcon}  variant={props.items[i].variant}
                                    />
                                }
                                suffix={
                                    process.env.REACT_APP_TOOLTIP_NAVI !==
                                    undefined ? (
                                        <>
                                            <TooltipIcon
                                                Store={Store}
                                                locale={props.value}
                                                content={props.header}
                                                name={props.items[i].name}
                                            />
                                        </>
                                    ) : (
                                        ''
                                    )
                                }
                            >
                                {IntlMessageValue(
                                    props.value,
                                    props.items[i].name,
                                    Store
                                )}
                            </MenuItem>
                        </>
                    )
                }
            } else {
                if (
                    props.items[i].roles?.includes(
                        localStorage.getItem('userRole') as string
                    )
                ) {
                    menuItem.push(
                        <>
                            <MenuItem
                                data-link={props.items[i].route}
                                key={'MenuItem' + i}
                                className={
                                    'MenuItem' +
                                    (checkLocation(
                                        URLLocation[1],
                                        TargetLocation
                                    ) === true
                                        ? ' activeSidebar'
                                        : ' deactiveSidebar')+ ' unLink'
                                }
                                component={
                                    <Link
                                        key={i}
                                        onClick={props.setActive(
                                            props.items[i].route
                                        )}
                                        to={props.items[i].route}
                                    />
                                }
                            >
                                {IntlMessageValue(
                                    props.value,
                                    props.items[i].name,
                                    Store
                                )}
                            </MenuItem>
                        </>
                    )
                }
            }
        }
    }
    return <> {menuItem} </>
}

export default ItemMenu

export const checkLocation = (url: any, target: any) => {
    let TargetLocation = target.split('/')

    if (url !== '/') {
        if (url === TargetLocation[1]) {
            return true
        }
    } else {
        if (window.location.pathname === target) {
            return true
        }
    }

    return false
}
