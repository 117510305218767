import {apiRoot} from "../../RestFullApi/ApiHook";
import SunEditor from "suneditor-react";
import React, { Key } from "react";
import {complex} from "./Config/complex";
import {basic} from "./Config/basic";
import { formatting } from "./Config/formating";

interface SuneditorProps {
    config: 'basic' | 'complex' | 'formatting';
    name?:string
    defaultValue?:string | undefined
    lang: "en" | "da" | "de" | "es" | "fr" | "ja" | "ko" | "pt_br" | "nl" | "ru" | "it" | "zh_cn" | "ro" | "pl" | "ckb" | "lv" | "se" | "ua" | "he" ;
    mode?: "classic" | "inline" | "balloon" | "balloon-always" | undefined
    key?:string | number | Key | null | undefined
    height?: string;
}

const index = (props:SuneditorProps) => {
    return (<>
        <SunEditor
            setDefaultStyle={
                'font-family: var(--bs-body-font-family); font-size: 16px;'
            }
            name={props.name}
            lang={props.lang}
            defaultValue={props.defaultValue}
            key={props.key}
            height={props.height}
            setOptions={{
                mode: props.mode,
                toolbarContainer: '#custom_toolbar',
                buttonList: (props.config === 'complex' ? complex : props.config === 'basic' ? basic : props.config === 'formatting' ? formatting : basic),
                attributesWhitelist: {
                    div: 'id|class',
                    p: 'id|class',
                    h: 'id|class',
                    span: 'id|class'
                },
                linkNoPrefix: true
            }}
        />
    </>)
}

export default index;
