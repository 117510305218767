
import OrganistationOverview from "./Pages/index";
import React from "react";

export const OrganisationConfig = (value:any) => {
        return [
            {
                path: '/organisation/overview/:page',
                elements: <OrganistationOverview value={value}/>,
                roles: ['superadmin', 'admin',
                    'ticketsystem',
                    'worktime',
                    'teamtakt']
            }
        ]

}

export const OrganisationMenuConfig = (value:any) => {

        return [

            {
                name: 'Organisation',
                route: '/organisation/overview/1',
                icon: 'corporate_fare',
                roles: ['superadmin', 'admin',
                    'ticketsystem',
                    'worktime',
                    'teamtakt']
            }

        ]

}

export const Software_Support = () => [
    {
        title: 'Support Ticket',
        href: '/support/software/overview/1',
        undertitle: 'Software-Support',
        icon: 'manage_accounts',
        text: 'Software-Support',
        roles: ['superadmin', 'admin',
            'ticketsystem',
            'worktime',
            'teamtakt']
    }
]
