import {useEffect, useState} from "react";
import {error} from "../../../../@WUM/core/Elements/index";
import {ticketsystem2} from "../request/ticketsystem2";
import {ticketApiType} from "../request/ticket.interface";
import {dashboardApiInterface} from "../request/dashboard.interface";
import {PrioApiType} from "../../../ticketsystem/API/request/Prio.interface";
import {checkLogin} from "../../../../@WUM/templates/default/Auth/service/AuthService";
import {LabelsApiType} from "../../../ticketsystem/API/request/Labels.interface";
import {ticket} from "../../../ticketsystem/API/request/ticket";

export const ResponseAllTickets = (fetchData:any,archiv:any) => {
    const [posts, setPosts] = useState<ticketApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticketsystem2
                .getPosts(archiv)
                .then((data) => {
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [fetchData,archiv])

    return isError ? isError : posts
}

export const ResponseSingleTickets = (id:any,fetchData:any,setValue:any) => {
    const [posts, setPosts] = useState<ticketApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticketsystem2
                .getPostsSingle(id)
                .then((data) => {
                    setPosts(data)
                    setValue(data[0].description)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,fetchData,setValue])

    return isError ? isError : posts
}

export const ResponseAllComment = (id:any,fetchData:any) => {
    const [posts, setPosts] = useState<ticketApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticketsystem2
                .getPostsSingleComment(id)
                .then((data) => {
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,fetchData])

    return isError ? isError : posts
}
export const ResponseDashbaordTask = (id: any) => {
    const [posts, setPosts] = useState<dashboardApiInterface>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticketsystem2
                .getPostsTaskDashbaord(id)
                .then((data) => {
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}
export const ResponseAllTemplate = (id: any) => {
    const [posts, setPosts] = useState<PrioApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticketsystem2
                .getPostsTemplate()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseAllNotifictions = (id: any) => {
    const [posts, setPosts] = useState<PrioApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticketsystem2
                .getPostsNotifications()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}
export const ResponseAllTriggerTicket = () => {
    const [posts, setPosts] = useState<LabelsApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ticketsystem2
                .getPostsTrigger()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [])

    return isError ? isError : posts
}
