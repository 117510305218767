
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {closeEsc, collapse} from "../../../ticketsystem/modules/new/Pages/SingleTask";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import MIcon from "../../../../@WUM/core/Elements/MIcon";
import {IntlMessageValue, UniForm} from "../../../../@WUM/core/Elements";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import {ApiRoot} from "../../../../@WUM/core/RestFullApi/api";
import InputGroup from 'react-bootstrap/InputGroup';
import {notify_save, setNavi} from "../../../../@WUM/core/Function/index";
import {
    ResponseDeparmtent,
    ResponseDeparmtentDetail,
    ResponseSingleDeparmtentDetail
} from "../API/response/support_software";
import {SpinnerJSX} from "../../../ticketsystem/modules/components/Spinner";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ReactDOM from "react-dom";
import SunEditor from "suneditor-react";
import SunEditorConfig from "../../../ticketsystem2.0/Pages/components/SuneditorConfig";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Overview = (props:{value:any}) =>{
    const Store = useSelector((state: any) => state)
    const [fetchData, setFetchData] = useState(0);
    setNavi({
        route:'/organisation/overview/'
    })
    const [did, setDid] = useState('0')
    const Department = ResponseDeparmtent('1');
    const Detail = ResponseDeparmtentDetail('1');

    const [departmensJSX, setDepartmensJSX] = useState<JSX.Element[]>([]);
    const Single = ResponseSingleDeparmtentDetail(did,Store,setDepartmensJSX);


    useEffect(() => {

        if(did !== '0'){
            const departmentsJSXString = departmensJSX.join(''); // Join the JSX elements here

            // Inject the joined string into the FormBody
            const formBodyElement = document.getElementById('FormBody') as HTMLDivElement;
            if (formBodyElement) {
                let newElement = departmensJSX
                let temp = document.createElement('div')
                ReactDOM.render(newElement, temp)
                let ele = document.getElementById('FormBody') as HTMLDivElement
                if(ele !== null) {
                    ele.innerHTML = ''
                    ele.prepend(temp)
                }
            } else {
                console.error('Element mit ID "FormBody" nicht gefunden.');
            }
        }

        }, [departmensJSX,did])

    const AddedFunc3 = (form:any,formParam:any) => {

        let Dialog = document.getElementById('addDialogdynamik') as HTMLDivElement;
        Dialog.classList.add("show");
        Dialog.style.display = 'block';
        if(form !== undefined) {
            let newElement = form(formParam)
            let temp = document.createElement('div')
            ReactDOM.render(newElement, temp)
            let ele = document.getElementById('injectModalTask') as HTMLDivElement
            if(ele !== null) {
                ele.innerHTML = ''
                ele.prepend(temp)
            }
        }
    }




    const collapseGeneralSettings = (e:any,id:any,arrowShow:any=true,noneShow:any = [],withLabel:any=false,setFetchData?:any) => {

        let Collapse = document.getElementById(id) as HTMLDivElement;
        if (Collapse.classList.contains("d-none") === true) {
            Collapse.classList.remove("d-none");
            Collapse.classList.add("d-block");

            for(let ns=0;ns<=noneShow.length;ns++) {
                let CollapseClose = document.getElementById(noneShow[ns]) as HTMLDivElement;
                CollapseClose.classList.add("d-none");
                CollapseClose.classList.remove("d-block");


            }
        }
    }

    const editEmailHeaderFooterSettings = (e:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            mandantenID:formdata.get('mandantenID'),
            header: formdata.get('header'),
            footer:formdata.get('footer')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/email/save/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result))
            .catch((error) => errorForm(error))
    }

    const editSignaturSettings = (e:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            mandantenID:formdata.get('mandantenID'),
            signatur: formdata.get('signatur')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/email/save/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result))
            .catch((error) => errorForm(error))
    }

    const editEmailSettings = (e:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            imapUser: formdata.get('imapUser'),
            imapPassword: formdata.get('imapPassword'),
            imapHost: formdata.get('imapHost'),
            imapPort: formdata.get('imapPort'),
            imapSSL: formdata.get('imapSSL'),
            smtpUser: formdata.get('smtpUser'),
            smtpPassword: formdata.get('smtpPassword'),
            smtpHost: formdata.get('smtpHost'),
            smtpPort: formdata.get('smtpPort'),
            smtpSSL: formdata.get('smtpSSL'),
            mandantenID:formdata.get('mandantenID')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'yoptimize/email/save/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result))
            .catch((error) => errorForm(error))
    }

    const successForm = (result:any) => {
        setFetchData(Date.now())
        notify_save()


    }

    const CloseMember = (indiId?:any,indclear?:any) => {
        let Dialog = document.getElementById((indiId !== undefined && indiId !== null ? indiId : 'addDialogdynamik')) as HTMLDivElement;
        Dialog.style.display = 'none';

        if(indclear !== null && indclear !== undefined) {
            for(let i =0;i<indclear.length;i++) {
                let tmp = document.getElementById(indclear[i].id) as HTMLInputElement;

                if(tmp !== null) {
                    tmp.value = indclear[i].value;
                }
            }
        }

        let Task = document.getElementById('TaskId') as HTMLInputElement;

        if(Task !== null) {
            Task.value = '';
        }

        let Project = document.getElementById('ProjectId') as HTMLInputElement;

        if(Project !== null){
            Project.value = ''
        }

    }

    const editDepartment = (e:any,CloseMember:any,CloseMemberID:any) => {
        e.preventDefault()
        let data = e.currentTarget

        var formdata = new FormData(data)
        var raw = JSON.stringify({
            id: formdata.get('id'),
            name: formdata.get('name'),
            content: formdata.get('content')
        })

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }
        CloseMember()
        /*fetch(ApiRoot()+'template/ticket/create/yoptimize/', requestOptions)
            .then((response) => response.text())
            .then((result) => successForm(result,CloseMember))
            .catch((error) => errorForm(error,CloseMember))*/
    }



    const editDepartments = (data:any) => {

        console.log(data)
        setDid(data.did)

        let Email = [data.email]

        return(<>

                <div className="modal-dialog">
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        noValidate
                        onSubmit={(e) =>
                            editDepartment(e,CloseMember,'addDialogdynamik')
                        }

                    >
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title h4">{data.title}</div>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={() => CloseMember()} />
                            </div>
                            <Tabs
                                defaultActiveKey="Position"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="Position" title="Position">
                                    <div className="modal-body pt-3" id={'FormBody'}>
                                        {departmensJSX}
                                    </div>
                                </Tab>
                                <Tab eventKey="Email" title="Email">
                                    <Form
                                        id={'form001'}
                                        className={'formControl'}
                                        noValidate autoComplete="none"
                                        onSubmit={(e) =>
                                            editEmailSettings(e)
                                        }
                                    >
                                        <h3>Email Settings</h3>
                                        <h4>E-Mail-Eingangsserver (IMAP)</h4>
                                        <Form.Control type="hidden" name={'id'} defaultValue={Email.length === 0 || Email[0] === undefined ? '0' : Email[0].id}/>
                                        <Form.Control type="hidden" name={'mandantenID'} defaultValue={Email.length === 0 || Email[0] === undefined ? '0' : Email[0].mandantenID}/>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Benutzername:</Form.Label>
                                            <Form.Control type="text" name={'imapUser'}
                                                          defaultValue={Email.length === 0 || Email[0] === undefined ? '' : Email[0].imapUser}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Passwort:</Form.Label>
                                            <Form.Control type="password" name={'imapPassword'} autoComplete={"false"}
                                                          defaultValue={Email.length === 0 || Email[0] === undefined ? '' : Email[0].imapPassword}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Hostname</Form.Label>
                                            <Form.Control type="text" name={'imapHost'}
                                                          defaultValue={Email.length === 0 || Email[0] === undefined ? '' : Email[0].imapHost}/>
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Port:</Form.Label>
                                                    <Form.Control type="text" name={'imapPort'}
                                                                  defaultValue={Email.length === 0 || Email[0] === undefined ? '' : Email[0].imapPort}/>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={2} md={2} xl={2} xxl={2}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label></Form.Label>
                                                    <Form.Check
                                                        style={{marginTop: '15px'}}
                                                        label={'TLS/SSL verwenden'}
                                                        defaultChecked={Email.length === 0 || Email[0] === undefined ? false : (Email[0].imapSSL === '1' ? true : false)}
                                                        name={'imapSSL'}
                                                        type={'checkbox'}
                                                        id={'to_staff'}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <h4>E-Mail-Ausgangsserver (SMTP)</h4>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Benutzername:</Form.Label>
                                            <Form.Control type="text" name={'smtpUser'}
                                                          defaultValue={Email.length === 0 || Email[0] === undefined ? '' : Email[0].smtpUser}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Passwort:</Form.Label>
                                            <Form.Control type="password" name={'smtpPassword'}
                                                          defaultValue={Email.length === 0 || Email[0] === undefined ? '' : Email[0].smtpPassword}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Hostname:</Form.Label>
                                            <Form.Control type="text" name={'smtpHost'}
                                                          defaultValue={Email.length === 0 || Email[0] === undefined ? '' : Email[0].smtpHost}/>
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Port:</Form.Label>
                                                    <Form.Control type="text" name={'smtpPort'}
                                                                  defaultValue={Email.length === 0 || Email[0] === undefined ? '' : Email[0].smtpPort}/>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={2} md={2} xl={2} xxl={2}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label></Form.Label>
                                                    <Form.Check
                                                        style={{marginTop: '15px'}}
                                                        label={'TLS/SSL verwenden'}
                                                        defaultChecked={Email.length === 0 || Email[0] === undefined ? false : (Email[0].smtpSSL === '1' ? true : false)}
                                                        name={'smtpSSL'}
                                                        type={'checkbox'}
                                                        id={'to_staff'}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Tab>

                            </Tabs>

                            <div className="modal-footer mt-3">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => CloseMember()}>Schließen
                                </button>
                                <button type="submit" id={'AttachDocumentSendButton'} className="btn btn-primary float-end" >{IntlMessageValue('de', 'common.save', Store)}</button>
                            </div>


                        </div>
                    </Form>
                </div>
            </>
        )
        }

    const errorForm = (error:any)=> {
        console.log(error)
    }



    if (Department !== null && Department !== undefined && typeof Department !== "boolean" && Department.length !== 0 &&
        Detail !== null && Detail !== undefined && typeof Detail !== "boolean" && Detail.length !== 0) {

        let AllNotificationHoldder= [];

        let NotificationNumber = 0;
        if (typeof Department !== "boolean" && Department?.length > 0) {
            for (let c = 0; c < Department.length; c++) {
                let positionCount = 0;
                let did = Department[c].id;
                let UserCount = 0;
                let State = 0;
                let email:any;
                for (let d = 0; d < Detail.length; d++) {

                    if (Department[c].name === Detail[d].departments.name) {
                        positionCount = Detail[d].departments.positions.length;
                        State = Detail[d].state
                        email = Detail[d].postOffice
                    }

                    if(did !== 0) {
                        for (let u = 0; u < Detail[0].departments.Users.length; u++) {
                            if(did === Detail[0].departments.Users[u].did) {
                                UserCount++;
                            }
                        }

                    }
                }


                NotificationNumber++;
                AllNotificationHoldder.push(
                    <>
                        <tr>
                            <td style={{width: '200px'}}>{
                                IntlMessageValue('de',  Department[c].name, Store)
                               }</td>
                            <td style={{width: '250px'}}>{positionCount}</td>
                            <td style={{width: '250px'}}>{State === 1 ? 'Aktiv' : 'Inaktiv'}</td>
                            <td style={{width: '150px'}}>{UserCount}</td>
                            <td style={{width: '40px'}} className={'text-center'}>
                                <Button variant="primary"
                                onClick={()=>AddedFunc3(editDepartments,{id:0,did:did,email:email,title:IntlMessageValue('de',  Department[c].name, Store)})}>
                                    {IntlMessageValue('', 'cps.table.edit', Store)}
                                </Button>
                            </td>
                            <td style={{width: '40px'}} className={'text-center'}>
                                <Button variant="danger"
                                       >{IntlMessageValue('', 'cps.table.delete', Store)}</Button>
                            </td>
                        </tr>
                    </>
                )
            }
        }

        return(
            <>
                <div className={'ticketsystem'} style={{paddingLeft: '25px'}}>
                    <Row>
                        <Col xs={2} md={2} xl={2} xxl={2}>
                            <h3 id={'headline'}>Organisation</h3>
                            <h4 onClick={(e) => collapse(e, 'actions')}>{'Actions'}
                                <span id={'actions_arrow'} className="material-icons" style={{verticalAlign: 'bottom'}}>
                            expand_less
                        </span>
                            </h4>
                            <div id={'actions'} className={'d-block'}>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px', cursor: 'pointer'}}
                                                      onClick={(e) => collapseGeneralSettings(e, 'Template', false, ['Signatur', 'Settings'])}>
                                            <MIcon variant={'fill'} className={'mr-3'}
                                                   style={{verticalAlign: 'middle'}}>
                                                badge
                                            </MIcon>
                                            Abteilungen
                                        </Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px', cursor: 'pointer'}}
                                                      onClick={(e) => collapseGeneralSettings(e, 'Signatur', false, ['Template', 'Settings'])}>
                                            <MIcon variant={'fill'} className={'mr-3'}
                                                   style={{verticalAlign: 'middle'}}>
                                                add_moderator
                                            </MIcon>
                                            Neue Position vorschlagen
                                        </Navbar.Brand>
                                    </Container>
                                </Navbar>
                                <Navbar className="bg-body-transparent">
                                    <Container>
                                        <Navbar.Brand style={{fontSize: '16px', cursor: 'pointer'}}
                                                      onClick={(e) => collapseGeneralSettings(e, 'Settings', false, ['Template', 'Signatur'])}>
                                            <MIcon variant={'fill'} className={'mr-3'}
                                                   style={{verticalAlign: 'middle'}}>
                                                groups
                                            </MIcon>
                                            Teams
                                        </Navbar.Brand>
                                    </Container>
                                </Navbar>
                            </div>
                        </Col>
                        <Col>

                            <div id={'Template'} className={'d-block'}>
                                <Card>
                                    <Card.Body>
                                        <Form
                                            id={'form001'}
                                            className={'formControl'}
                                            noValidate autoComplete="none"
                                            onSubmit={(e) =>
                                                editEmailHeaderFooterSettings(e)
                                            }
                                        >
                                            <h4 className={'mt-3 mb-3'}>Abteilungen</h4>
                                            <Table striped bordered hover style={{width: '100%'}}>
                                                <thead>
                                                <tr>
                                                    <th style={{width: '150px'}}>{IntlMessageValue('', 'common.name', Store)}</th>
                                                    <th style={{width: '40px'}}>Position</th>
                                                    <th style={{width: '40px'}}>Ticket System 2.0</th>
                                                    <th style={{width: '10px'}}>Mitarbeiter zugewiesen</th>
                                                    <th style={{width: '40px'}}></th>
                                                    <th style={{width: '40px'}}></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {AllNotificationHoldder}
                                                </tbody>
                                            </Table>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div id={'Signatur'} className={'d-none'}>
                                <Card>
                                    <Card.Body>
                                        <Form
                                            id={'form001'}
                                            className={'formControl'}
                                            noValidate autoComplete="none"
                                            onSubmit={(e) =>
                                                editSignaturSettings(e)
                                            }
                                        >
                                            <h3>Neue Position vorschlagen</h3>
                                            <button type="submit" id={'AttachDocumentSendButton'}
                                                    className="btn btn-primary float-end mt-3">{IntlMessageValue('de', 'common.save', Store)}
                                            </button>
                                        </Form>
                                    </Card.Body>
                                </Card>

                            </div>
                            <div id={'Settings'} className={'d-none'}>
                                <Card>
                                    <Card.Body>
                                        <Form
                                            id={'form001'}
                                            className={'formControl'}
                                            noValidate autoComplete="none"
                                            onSubmit={(e) =>
                                                editEmailSettings(e)
                                            }
                                        >
                                            <h3>Teams</h3>


                                            <Row>

                                            </Row>

                                            <Row>

                                            </Row>
                                            <button type="submit" id={'AttachDocumentSendButton'}
                                                    className="btn btn-primary float-end">{IntlMessageValue('de', 'common.save', Store)}
                                            </button>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <div role="dialog" aria-modal="true" className="offcanvas offcanvas-end"
                         id="addDialogdynamik"
                         style={{overflowY: 'auto', minWidth: '400px', top: '60px', right: '10px', width: '40%'}}>

                        <div className="offcanvas-body">
                            <div id="injectModalTask"></div>
                        </div>
                    </div>
                </div>
            </>
        )

    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'}/>
            </>
        )
    }
}

export default Overview;
