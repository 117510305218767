import React, {useEffect, useState} from "react";
import {error, IntlMessageValue, UniForm} from "../../../../../@WUM/core/Elements/index";
import {Department} from "../request/support_software";
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {_UserInterface, depatments} from "../request/SupportSoftwareApi.interface";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import ReactDOM from "react-dom";

export const ResponseDeparmtentDetail = (id:any) => {
    const [posts, setPosts] = useState<depatments[]>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
            ;(async () => {
                    await Department
                        .getPostsDepartmentDetail()
                        .then((data) => {
                            checkLogin(data)
                            setPosts(data)
                            //console.log(data);
                        })
                        .catch((err) => {
                            error(err)
                            setIsError(true)
                        })
                })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseSingleDeparmtentDetail = (did:any,Store:any,setDepartmensJSX:any) => {
    const [posts, setPosts] = useState<depatments[]>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Department
                .getPostsSingleDepartmentDetail(did)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    let positionsHolder = [];

                        for (let p = 0; p < data.length; p++) {
                            positionsHolder.push(
                                <>
                                    <Row>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">Position</InputGroup.Text>
                                                <Form.Control
                                                    placeholder="Username"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    defaultValue={
                                                        IntlMessageValue('de', data[p].name, Store)
                                                    }
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col>

                                            <UniForm.InputGroup>
                                                <UniForm.InputGroupText text={'Status'}/>
                                                <UniForm.ToggleSelect defaultValue={'Intern'} short={'responsibility'}
                                                                      style={{display: "block"}} id={'ResponsibilityMaker'}
                                                                      name={'responsibility'}
                                                                      ToggleButton={[{
                                                                          value: 'Aktiv',
                                                                          label: 'Aktiv'
                                                                      }, {value: 'Inaktiv', label: 'Inaktiv'}]}/>

                                            </UniForm.InputGroup>
                                        </Col>
                                    </Row>

                                </>
                            )

                        setDepartmensJSX(positionsHolder)
                    }
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [did])

    return isError ? isError : posts
}

export const ResponseDeparmtent = (id: any) => {
    const [posts, setPosts] = useState<depatments[]>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Department
                .getPostsDepartment()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}
