import {IntlMessageValue} from "./IntlMessage";
import React from "react";
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
const DataList = (props:{fetch:any,id:any,name:any,label:any,idInput:any,value1:any,value2:any,Store:any,idSet?:any,defaultValue?:any}) => {

    let FetchHolder = [];

    if(props.fetch === null || props.fetch === undefined) {
        return (
            <>

            </>
        )
    }

    for(let f=0;f<props.fetch.length;f++){

        FetchHolder.push(
            <>
                <option value={props.fetch[f][props.value1] + ' - ' + props.fetch[f][props.value2]} key={f} data-id={''+f+''} />

            </>
        )
    }

    return(
        <>
            <label htmlFor={props.idInput} className="form-label" style={{width: 'max-content',float:'left'}}>{props.label}</label>
            <input className="form-control" list={props.id} name={props.name} id={props.idInput} style={{width: 'max-content',float:'right'}} defaultValue={props.defaultValue}
                   placeholder={IntlMessageValue('de', 'common.datalist.label', props.Store)}
                   onFocus={(e) => e.target.value=''}
                   onChange={(e)=>SwitchContainer(props.fetch,props.id, props.idSet,e.target.value)}
            />
            <datalist id={props.id} >
                <option></option>
                {FetchHolder}
            </datalist>

        </>
    )
}

export default DataList;


export const SwitchContainer = (fetch:any,id:any,idSet:any,value:any) => {
    let DOptions = (document.getElementById(id) as HTMLDataListElement).options
    let ids = '';
    for(let Options=0;Options<DOptions.length;Options++){
        if(DOptions[Options].value === value) {
            ids = DOptions[Options].dataset.id as string;
        }
    }

    let Data = fetch[ids];

    if(Data === undefined) { return }
    for(let set=0;set<idSet.length;set++){

        if(idSet[set].type === 'input') {
            let Holder = document.getElementById(idSet[set].id) as HTMLInputElement;

            if(idSet[set].key === false) {
                let Label = '';
                for(let l=0;l<idSet[set].keyMap.length;l++){
                    Label = Label + Data[idSet[set].keyMap[l]] + "\r\n"
                }
                Holder.value = Label
            } else {
                Holder.value = Data[idSet[set].key]
            }
        } else if(idSet[set].type === 'textarea'){
            let Holder = document.getElementById(idSet[set].id) as HTMLTextAreaElement;

            if(idSet[set].key === false) {
                let Label = '';
                for(let l=0;l<idSet[set].keyMap.length;l++){
                    Label = Label + Data[idSet[set].keyMap[l]] + "\r\n"
                }
                Holder.value = Label
            } else {
                Holder.value = Data[idSet[set].key]
            }
        } else {
            let Holder = document.getElementById(idSet[set].id) as HTMLInputElement;

            if(idSet[set].key === false) {
                let Label = '';
                for(let l=0;l<idSet[set].keyMap.length;l++){
                    Label = Label + Data[idSet[set].keyMap[l]] + "\r\n"
                }
                Holder.value = Label
            } else {
                Holder.value = Data[idSet[set].key]
            }
        }




    }

}


export const  DataListSingle = (props:{fetch:any,response:any,id:any,name:any,label:any,idInput:any,value1:any,value2:any,Store:any,idSet?:any,value3?:any}) => {

    let FetchHolder = [];

    if(props.fetch === null || props.fetch === undefined) {
        return (
            <>

            </>
        )
    }

    for(let f=0;f<props.fetch.length;f++){

        FetchHolder.push(
            <>
                <option value={props.fetch[f][props.value1] + '  ' + props.fetch[f][props.value2] + ' '+  (props.value3 !== undefined ? props.fetch[f][props.value3] : '')} key={f} data-id={''+f+''} />
            </>
        )
    }

    return(
        <>
            <Row>
            <label htmlFor={props.idInput} className="form-label" style={{width: 'max-content',float:'left'}}>{props.label}</label>
                <br/>
            <input className="form-control" list={props.id} name={props.name} id={props.idInput} style={{width: 'max-content',float:'right'}}
                   placeholder={IntlMessageValue('de', 'common.datalist.label', props.Store)} defaultValue={props.response}

            />
            <datalist id={props.id}  >
                <option></option>
                {FetchHolder}
            </datalist>
            </Row>
        </>
    )
}

export const  DataListSingleNoneLabel = (props:{fetch:any,response:any,id:any,name:any,label:any,idInput:any,value1:any,value2:any,Store:any,idSet?:any,value3?:any,class?:any}) => {

    let FetchHolder = [];


    if(props.fetch === null || props.fetch === undefined) {
        return (
            <>

            </>
        )
    }

    for(let f=0;f<props.fetch.length;f++){

        FetchHolder.push(
            <>
                <option value={props.fetch[f][props.value1] + ' - ' + props.fetch[f][props.value2] + ' '+  (props.value3 !== undefined ? props.fetch[f][props.value3] : '')} key={f} data-id={''+f+''} />
            </>
        )
    }

    return(
        <>
            <Row>
                <input className="form-control" list={props.id} name={props.name} id={props.idInput} style={{width: 'max-content',float:'right'}}
                       placeholder={IntlMessageValue('de', 'common.datalist.label', props.Store)} defaultValue={props.response}

                />
                <datalist id={props.id}  >
                    <option></option>
                    {FetchHolder}
                </datalist>
            </Row>
        </>
    )
}

export const  DataListSingleTime = (props:{fetch:any,response:any,id:any,name:any,label:any,idInput:any,value1:any,value2:any,Store:any,idSet?:any,value3?:any}) => {

    let FetchHolder = [];

    if(props.fetch === null || props.fetch === undefined) {
        return (
            <>

            </>
        )
    }

    for(let f=0;f<props.fetch.length;f++){

        FetchHolder.push(
            <>
                <option value={props.fetch[f][props.value1] + ' - ' + props.fetch[f][props.value2] + ' '+  (props.value3 !== undefined ? props.fetch[f][props.value3] : '')} key={f} data-id={''+f+''} />
            </>
        )
    }

    return(
        <>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">{props.label}</InputGroup.Text>
                    <Form.Control
                        list={props.id} name={props.name} id={props.idInput}
                        defaultValue={props.response}
                    />
                </InputGroup>
                <datalist id={props.id}  >
                    <option></option>
                    {FetchHolder}
                </datalist>
            </Row>
        </>
    )
}

export const  DataListSingleWithOnchange = (props:{fetch:any,Change:any,response:any,id:any,name:any,label:any,idInput:any,value1:any,value2:any,Store:any,idSet?:any,value3?:any}) => {

let FetchHolder = [];

    if(props.fetch === null || props.fetch === undefined) {
        return (
            <>

            </>
        )
    }

for(let f=0;f<props.fetch.length;f++){

    FetchHolder.push(
        <>
            <option value={props.fetch[f][props.value1] + ' - ' + props.fetch[f][props.value2] + ' '+  (props.value3 !== undefined ? props.fetch[f][props.value3] : '')} key={f} data-id={''+f+''} />
        </>
    )
}

return(
    <>
        <Row>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">{props.label}</InputGroup.Text>
                <Form.Control
                    list={props.id} name={props.name} id={props.idInput}
                    defaultValue={props.response}
                    onChange={(e)=>props.Change(e.target.value)}
                />
            </InputGroup>
            <datalist id={props.id}  >
                <option></option>
                {FetchHolder}
            </datalist>
        </Row>
    </>
)
}
