
import {requests} from "../../../../../@WUM/core/RestFullApi/api";
import {_UserInterface, depatments} from "./SupportSoftwareApi.interface";

export const support_software = {
    getPosts: (id:any): Promise<_UserInterface> =>
        requests.get(`User/${id}/`),
    getPostsDepartment: (): Promise<depatments[]> =>
        requests.get(`yoptimize/fetch/departments/`),
    updatePost: (post: any, id: any): Promise<_UserInterface> =>
        requests.put(`User/update/${id}/`, post),
}
